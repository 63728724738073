.cdk-overlay-pane--dialog-with-close-btn {
  .mat-dialog-container {
    position: relative;
    padding-top: 40px;
  }
}

.cdk-overlay-pane--fullscreen-dialog-for-mobile {
  &,
  .mat-dialog-container {
    @include media-breakpoint-down(sm) {
      max-width: 100% !important;
      max-height: 100% !important;
      min-height: 100vh !important;
      min-width: 100% !important;
    }
  }

  .mat-dialog-content {
    max-height: 90vh;
  }

  .mat-dialog-actions {
    display: block;
  }
}
