.mat-expansion-panel {
  .mat-expansion-panel-header {
    @include typography('small-paragraph');
    color: $eq-black-100;
    padding: 4px 0px;
  }

  .mat-expansion-indicator::after {
    padding: 2px;
    margin-bottom: 3px;
  }

  .mat-expansion-panel-header.mat-expanded {
    .mat-expansion-indicator::after {
      color: $eq-dark-green;
    }
  }

  .mat-expansion-panel-content {
    @include typography('small-paragraph');
  }

  .mat-expansion-indicator {
    margin-right: 20px;
    margin-left: 10px;
  }

  .mat-content {
    word-break: break-word;
    align-items: center;
  }
}

.mat-expansion-panel-spacing {
  margin: 0 0 !important;
}

.mat-expansion-panel.expansion-panel-stacked {
  box-shadow: none;
  border-bottom: 1px solid $eq-black-5;
  border-top: 1px solid $eq-black-5;
  border-radius: 0px;

  @include media-breakpoint-down(sm) {
    padding: 0 8px;
  }

  &.mat-expanded {
    box-shadow: $eq-box-shadow;
    background: $eq-black-3;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }
}
