$eq-black-100: #1b1d1d;
$eq-black-75: #545656;
$eq-black-50: #8d8e8e;
$eq-black-35: #c6c6c6;
$eq-black-15: #e0e0e0;
$eq-black-5: #f2f2f2;
$eq-black-3: #f8f8f8;
$eq-white: #ffffff;

$eq-body-small-grey: #757676;

$eq-green: #33d3be;
$eq-dark-green: #00a791;
$eq-light-green: rgba($eq-green, 0.12);
$eq-light-grey: #c6c6c6;
$eq-gradient: linear-gradient(44.99deg, #22c7d0 0%, #46dfaa 99.96%);
$eq-gradient-hover: linear-gradient(224.99deg, #22c7d0 0%, #46dfaa 99.96%);
$eq-gradient-dark: linear-gradient(225deg, #53d7cc 0%, #29af9d 100%);

$eq-error: #e40431;
$eq-light-error: rgba($eq-error, 0.12);
$eq-warning: #d39e33;
$eq-warning-dark: #e47a04;

$eq-box-shadow-button: 0 7px 8px -3px rgba(29, 29, 27, 0.12),
  0 12px 12px -6px rgba(29, 29, 27, 0.12);

$eq-box-shadow: 0 8px 20px 0 rgba(27, 29, 29, 0.1);

$eq-box-shadow-hover-focus: 0 24px 32px 0 rgba(27, 29, 29, 0.1);

$eq-dark-green-filter: invert(51%) sepia(46%) saturate(6916%) hue-rotate(147deg)
  brightness(98%) contrast(101%);
