.block {
  color: $eq-black-100;

  &--title {
    @include typography('block-title');
  }

  &--text {
    @include typography('block-text');
  }
}
