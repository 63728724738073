.button-arrow {
  @include typography(normal-button);
  text-decoration: none;
  display: flex;
  align-items: center;
  text-align: left;
  color: $eq-green;

  &:focus,
  &:hover {
    color: $eq-dark-green;
  }

  &--left:before {
    width: 24px;
    height: 24px;
    position: relative;
    flex-shrink: 0;
    margin-right: 8px;
    @include media-breakpoint-down(xs) {
      margin-right: 8px;
    }
    background-image: url('/assets/icons/32px_arrow-left.svg');
    content: '';
    display: block;
  }

  &--left:hover::before {
    filter: $eq-dark-green-filter;
  }

  &--left:focus::before {
    filter: $eq-dark-green-filter;
  }
}
