$eq-font-regular: 'Montserrat', 'Roboto', Arial, sans-serif;

@mixin typography($type) {
  $types: (
    footer-text: (
      font: calculateRem(24px) $eq-font-regular,
      line-height: 1.5,
    ),
    main-header: (
      font: calculateRem(44px) $eq-font-regular,
      line-height: 1.27,
      font-weight: 700,
      letter-spacing: calculateRem(-0.4px),
    ),
    secondary-header: (
      font: calculateRem(40px) $eq-font-regular,
      line-height: 1.3,
      font-weight: 700,
    ),
    big-paragraph: (
      font: calculateRem(18px) $eq-font-regular,
      line-height: 2,
      font-weight: 500,
    ),
    paragraph: (
      font: calculateRem(16px) $eq-font-regular,
      line-height: 1.7,
    ),
    small-paragraph: (
      font: calculateRem(14px) $eq-font-regular,
      line-height: 1.7,
      font-weight: 500,
    ),
    section-header: (
      font: calculateRem(28px) $eq-font-regular,
      line-height: calculateRem(40px),
      font-weight: 700,
    ),
    medium-section-header: (
      font: calculateRem(24px) $eq-font-regular,
      line-height: calculateRem(32px),
      font-weight: 700,
    ),
    small-section-header: (
      font: calculateRem(20px) $eq-font-regular,
      line-height: calculateRem(24px),
      font-weight: 500,
    ),
    block-title: (
      font: calculateRem(12px) $eq-font-regular,
      line-height: 1,
      font-weight: 700,
      letter-spacing: calculateRem(3px),
      text-transform: uppercase,
    ),
    block-text: (
      font: calculateRem(12px) $eq-font-regular,
      line-height: 2,
      font-weight: 500,
    ),
    sub-section-header: (
      font: calculateRem(14px) $eq-font-regular,
      line-height: calculateRem(16px),
      font-weight: 700,
    ),
    footer-section-header: (
      font: calculateRem(12px) $eq-font-regular,
      line-height: 1.3,
      font-weight: 700,
      text-transform: uppercase,
    ),
    label: (
      font: calculateRem(12px) $eq-font-regular,
      line-height: 1,
      font-weight: 500,
    ),
    small-label: (
      font: calculateRem(11px) $eq-font-regular,
      line-height: calculateRem(16px),
      font-weight: 700,
    ),
    big-label: (
      font: calculateRem(14px) $eq-font-regular,
      line-height: calculateRem(16px),
      font-weight: 500,
    ),
    small-value: (
      font: calculateRem(14px) $eq-font-regular,
      line-height: calculateRem(16px),
      font-weight: 500,
    ),
    value: (
      font: calculateRem(16px) $eq-font-regular,
      line-height: 1.125,
      font-weight: 700,
    ),
    big-value: (
      font: calculateRem(18px) $eq-font-regular,
      line-height: 1,
      font-weight: 700,
    ),
    extra-big-value: (
      font: calculateRem(32px) $eq-font-regular,
      line-height: 1,
      font-weight: 700,
    ),
    small-title: (
      font: calculateRem(18px) $eq-font-regular,
      line-height: 1,
      font-weight: 700,
    ),
    link: (
      font: calculateRem(12px) $eq-font-regular,
      line-height: 2,
      font-weight: 500,
      letter-spacing: calculateRem(0.83px),
    ),
    bold-link: (
      font: calculateRem(12px) $eq-font-regular,
      line-height: 2,
      font-weight: 700,
      letter-spacing: calculateRem(1px),
    ),
    big-link: (
      font: calculateRem(16px) $eq-font-regular,
      line-height: 1,
      font-weight: 700,
      letter-spacing: calculateRem(1.33px),
    ),
    normal-button: (
      font: calculateRem(14px) $eq-font-regular,
      line-height: 1.15,
      font-weight: 700,
      letter-spacing: calculateRem(1px),
    ),
    small-button: (
      font: calculateRem(12px) $eq-font-regular,
      line-height: 1.3333,
      font-weight: 700,
      letter-spacing: calculateRem(1px),
    ),
    small-bold-text: (
      font: calculateRem(10px) $eq-font-regular,
      line-height: 1.6,
      font-weight: 700,
      letter-spacing: calculateRem(0.83px),
    ),
    notification-title: (
      font: calculateRem(16px) $eq-font-regular,
      line-height: 1.5,
      font-weight: 700,
      letter-spacing: calculateRem(1px),
    ),
    notification-message: (
      font: calculateRem(12px) $eq-font-regular,
      line-height: 1.6,
      font-weight: 500,
    ),
    form-message: (
      font: calculateRem(12px) $eq-font-regular,
      font-weight: 500,
      line-height: calculateRem(16px),
    ),
    input: (
      font: calculateRem(14px) $eq-font-regular,
      line-height: 1.714,
    ),
    input-bold: (
      font: calculateRem(14px) $eq-font-regular,
      font-weight: 700,
      line-height: 1.714,
    ),
    checkbox-label: (
      font: calculateRem(13px) $eq-font-regular,
      font-weight: 500,
      line-height: 1.23,
    ),
    popup-title-small: (
      font: calculateRem(16px) $eq-font-regular,
      line-height: 1.5,
      font-weight: 700,
    ),
    bold-notification-message: (
      font: calculateRem(12px) $eq-font-regular,
      font-weight: 600,
    ),
    small-notification-title: (
      font: calculateRem(12px) $eq-font-regular,
      line-height: 1.6,
      font-weight: 600,
    ),
  );

  $selectedType: map-get($types, $type);

  @each $prop in map_keys($selectedType) {
    #{$prop}: map-get($selectedType, $prop);
  }
}
