.asset-link {
  @include typography(small-paragraph);
  display: flex;
  width: 100%;
  padding: 16px 24px;
  background: $eq-black-3;
  border-radius: 8px;

  &:hover,
  &:focus {
    background: $eq-white;
    box-shadow: $eq-box-shadow;
  }

  &__description {
    flex-grow: 1;
  }

  &__info {
    margin-left: 20px;
    flex-shrink: 0;
    color: $eq-green;
    align-self: center;
  }

  &__extension {
    font-weight: 700;
  }
}
