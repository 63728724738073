@charset "UTF-8";
/**
 * Reset Mixing
 * ================================================== */
/**
 * Disable Animation
 */
/**
 * Disable animation depends on Browser or Operation System configuration
 */
/**
 * Accessability. Black and White Mode
 */
/**
 * Accessability. Inverse Mode
 */
/**
 * Meter reset
 */
/**
 * Modern CSS Reset Tweaks
 * ================================================== */
html {
  scroll-behavior: smooth;
}

body {
  text-size-adjust: 100%;
  position: relative;
  width: 100%;
  min-height: 100vh;
}

/* Box sizing normalization */
*,
::after,
::before {
  box-sizing: border-box;
}

/* Elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/**
 * CSS Reset Tweaks
 *
 * http://meyerweb.com/eric/tools/css/reset/
 * v2.0-modified | 20110126
 * License: none (public domain)
 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  font-size: 100%;
  font: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Hide empty cell */
td:empty,
th:empty {
  opacity: 0;
}

/**
 * Input Reset
 */
input:required,
input {
  box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 */
[hidden] {
  display: none;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: none;
}

/* Make images easier to work with */
img {
  border: 0;
  max-width: 100%;
  display: inline-block;
  vertical-align: middle;
  height: auto;
}

/* Make pictures easier to work with */
picture {
  display: inline-block;
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0;
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
  border: 0;
  background: transparent;
}

button::-moz-focus-inner {
  border: 0;
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 */
input[type=checkbox],
input[type=radio] {
  padding: 0;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button {
  border: 0;
  background: transparent;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */
  resize: vertical;
}

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * Based on normalize.css v8.0.1
 * github.com/necolas/normalize.css
 */
hr {
  box-sizing: content-box;
  overflow: visible;
  background: #000;
  border: 0;
  height: 1px;
  line-height: 0;
  margin: 0;
  padding: 0;
  page-break-after: always;
  width: 100%;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 */
pre {
  font-family: monospace, monospace;
  font-size: 100%;
}

/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 100%;
  /* 2 */
}

/**
  * Add the correct font size in all browsers.
  */
small {
  font-size: 75%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -5px;
}

sup {
  top: -5px;
}

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1;
  margin: 0;
  padding: 0;
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
  outline: 0;
}

legend {
  color: inherit;
  white-space: normal;
  display: block;
  border: 0;
  max-width: 100%;
  width: 100%;
}

fieldset {
  min-width: 0;
}

body:not(:-moz-handler-blocked) fieldset {
  display: block;
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/*
 * Misc
 * ========================================================================== */
/**
 * Add the correct display in IE 10+.
 */
template {
  display: none;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1340px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1312px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-left: -16px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxl,
.col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1340px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xxl-first {
    order: -1;
  }

  .order-xxl-last {
    order: 13;
  }

  .order-xxl-0 {
    order: 0;
  }

  .order-xxl-1 {
    order: 1;
  }

  .order-xxl-2 {
    order: 2;
  }

  .order-xxl-3 {
    order: 3;
  }

  .order-xxl-4 {
    order: 4;
  }

  .order-xxl-5 {
    order: 5;
  }

  .order-xxl-6 {
    order: 6;
  }

  .order-xxl-7 {
    order: 7;
  }

  .order-xxl-8 {
    order: 8;
  }

  .order-xxl-9 {
    order: 9;
  }

  .order-xxl-10 {
    order: 10;
  }

  .order-xxl-11 {
    order: 11;
  }

  .order-xxl-12 {
    order: 12;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }
}
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.mt-3,
.my-3 {
  margin-top: 0.75rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 0.75rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 0.75rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1rem !important;
}

.m-5 {
  margin: 1.5rem !important;
}

.mt-5,
.my-5 {
  margin-top: 1.5rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 1.5rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 1.5rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 1.5rem !important;
}

.m-6 {
  margin: 2rem !important;
}

.mt-6,
.my-6 {
  margin-top: 2rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 2rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 2rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 2rem !important;
}

.m-7 {
  margin: 2.5rem !important;
}

.mt-7,
.my-7 {
  margin-top: 2.5rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 2.5rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 2.5rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 2.5rem !important;
}

.m-8 {
  margin: 3rem !important;
}

.mt-8,
.my-8 {
  margin-top: 3rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 3rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 3rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 3rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.mt-9,
.my-9 {
  margin-top: 4rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 4rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 4rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 4rem !important;
}

.m-10 {
  margin: 5rem !important;
}

.mt-10,
.my-10 {
  margin-top: 5rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 5rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 5rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 5rem !important;
}

.m-11 {
  margin: 6rem !important;
}

.mt-11,
.my-11 {
  margin-top: 6rem !important;
}

.mr-11,
.mx-11 {
  margin-right: 6rem !important;
}

.mb-11,
.my-11 {
  margin-bottom: 6rem !important;
}

.ml-11,
.mx-11 {
  margin-left: 6rem !important;
}

.m-12 {
  margin: 8rem !important;
}

.mt-12,
.my-12 {
  margin-top: 8rem !important;
}

.mr-12,
.mx-12 {
  margin-right: 8rem !important;
}

.mb-12,
.my-12 {
  margin-bottom: 8rem !important;
}

.ml-12,
.mx-12 {
  margin-left: 8rem !important;
}

.m-13 {
  margin: 10rem !important;
}

.mt-13,
.my-13 {
  margin-top: 10rem !important;
}

.mr-13,
.mx-13 {
  margin-right: 10rem !important;
}

.mb-13,
.my-13 {
  margin-bottom: 10rem !important;
}

.ml-13,
.mx-13 {
  margin-left: 10rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.pt-3,
.py-3 {
  padding-top: 0.75rem !important;
}

.pr-3,
.px-3 {
  padding-right: 0.75rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 0.75rem !important;
}

.pl-3,
.px-3 {
  padding-left: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1rem !important;
}

.p-5 {
  padding: 1.5rem !important;
}

.pt-5,
.py-5 {
  padding-top: 1.5rem !important;
}

.pr-5,
.px-5 {
  padding-right: 1.5rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 1.5rem !important;
}

.pl-5,
.px-5 {
  padding-left: 1.5rem !important;
}

.p-6 {
  padding: 2rem !important;
}

.pt-6,
.py-6 {
  padding-top: 2rem !important;
}

.pr-6,
.px-6 {
  padding-right: 2rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 2rem !important;
}

.pl-6,
.px-6 {
  padding-left: 2rem !important;
}

.p-7 {
  padding: 2.5rem !important;
}

.pt-7,
.py-7 {
  padding-top: 2.5rem !important;
}

.pr-7,
.px-7 {
  padding-right: 2.5rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 2.5rem !important;
}

.pl-7,
.px-7 {
  padding-left: 2.5rem !important;
}

.p-8 {
  padding: 3rem !important;
}

.pt-8,
.py-8 {
  padding-top: 3rem !important;
}

.pr-8,
.px-8 {
  padding-right: 3rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 3rem !important;
}

.pl-8,
.px-8 {
  padding-left: 3rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.pt-9,
.py-9 {
  padding-top: 4rem !important;
}

.pr-9,
.px-9 {
  padding-right: 4rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 4rem !important;
}

.pl-9,
.px-9 {
  padding-left: 4rem !important;
}

.p-10 {
  padding: 5rem !important;
}

.pt-10,
.py-10 {
  padding-top: 5rem !important;
}

.pr-10,
.px-10 {
  padding-right: 5rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 5rem !important;
}

.pl-10,
.px-10 {
  padding-left: 5rem !important;
}

.p-11 {
  padding: 6rem !important;
}

.pt-11,
.py-11 {
  padding-top: 6rem !important;
}

.pr-11,
.px-11 {
  padding-right: 6rem !important;
}

.pb-11,
.py-11 {
  padding-bottom: 6rem !important;
}

.pl-11,
.px-11 {
  padding-left: 6rem !important;
}

.p-12 {
  padding: 8rem !important;
}

.pt-12,
.py-12 {
  padding-top: 8rem !important;
}

.pr-12,
.px-12 {
  padding-right: 8rem !important;
}

.pb-12,
.py-12 {
  padding-bottom: 8rem !important;
}

.pl-12,
.px-12 {
  padding-left: 8rem !important;
}

.p-13 {
  padding: 10rem !important;
}

.pt-13,
.py-13 {
  padding-top: 10rem !important;
}

.pr-13,
.px-13 {
  padding-right: 10rem !important;
}

.pb-13,
.py-13 {
  padding-bottom: 10rem !important;
}

.pl-13,
.px-13 {
  padding-left: 10rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -0.75rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -0.75rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -0.75rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -0.75rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -0.75rem !important;
}

.m-n4 {
  margin: -1rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1rem !important;
}

.m-n5 {
  margin: -1.5rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -1.5rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -1.5rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -1.5rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -1.5rem !important;
}

.m-n6 {
  margin: -2rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -2rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -2rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -2rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -2rem !important;
}

.m-n7 {
  margin: -2.5rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -2.5rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -2.5rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -2.5rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -2.5rem !important;
}

.m-n8 {
  margin: -3rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -3rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -3rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -3rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -3rem !important;
}

.m-n9 {
  margin: -4rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -4rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -4rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -4rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -4rem !important;
}

.m-n10 {
  margin: -5rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -5rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -5rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -5rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -5rem !important;
}

.m-n11 {
  margin: -6rem !important;
}

.mt-n11,
.my-n11 {
  margin-top: -6rem !important;
}

.mr-n11,
.mx-n11 {
  margin-right: -6rem !important;
}

.mb-n11,
.my-n11 {
  margin-bottom: -6rem !important;
}

.ml-n11,
.mx-n11 {
  margin-left: -6rem !important;
}

.m-n12 {
  margin: -8rem !important;
}

.mt-n12,
.my-n12 {
  margin-top: -8rem !important;
}

.mr-n12,
.mx-n12 {
  margin-right: -8rem !important;
}

.mb-n12,
.my-n12 {
  margin-bottom: -8rem !important;
}

.ml-n12,
.mx-n12 {
  margin-left: -8rem !important;
}

.m-n13 {
  margin: -10rem !important;
}

.mt-n13,
.my-n13 {
  margin-top: -10rem !important;
}

.mr-n13,
.mx-n13 {
  margin-right: -10rem !important;
}

.mb-n13,
.my-n13 {
  margin-bottom: -10rem !important;
}

.ml-n13,
.mx-n13 {
  margin-left: -10rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 0.75rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 0.75rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 0.75rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 0.75rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 0.75rem !important;
  }

  .m-sm-4 {
    margin: 1rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1rem !important;
  }

  .m-sm-5 {
    margin: 1.5rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 1.5rem !important;
  }

  .m-sm-6 {
    margin: 2rem !important;
  }

  .mt-sm-6,
.my-sm-6 {
    margin-top: 2rem !important;
  }

  .mr-sm-6,
.mx-sm-6 {
    margin-right: 2rem !important;
  }

  .mb-sm-6,
.my-sm-6 {
    margin-bottom: 2rem !important;
  }

  .ml-sm-6,
.mx-sm-6 {
    margin-left: 2rem !important;
  }

  .m-sm-7 {
    margin: 2.5rem !important;
  }

  .mt-sm-7,
.my-sm-7 {
    margin-top: 2.5rem !important;
  }

  .mr-sm-7,
.mx-sm-7 {
    margin-right: 2.5rem !important;
  }

  .mb-sm-7,
.my-sm-7 {
    margin-bottom: 2.5rem !important;
  }

  .ml-sm-7,
.mx-sm-7 {
    margin-left: 2.5rem !important;
  }

  .m-sm-8 {
    margin: 3rem !important;
  }

  .mt-sm-8,
.my-sm-8 {
    margin-top: 3rem !important;
  }

  .mr-sm-8,
.mx-sm-8 {
    margin-right: 3rem !important;
  }

  .mb-sm-8,
.my-sm-8 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-8,
.mx-sm-8 {
    margin-left: 3rem !important;
  }

  .m-sm-9 {
    margin: 4rem !important;
  }

  .mt-sm-9,
.my-sm-9 {
    margin-top: 4rem !important;
  }

  .mr-sm-9,
.mx-sm-9 {
    margin-right: 4rem !important;
  }

  .mb-sm-9,
.my-sm-9 {
    margin-bottom: 4rem !important;
  }

  .ml-sm-9,
.mx-sm-9 {
    margin-left: 4rem !important;
  }

  .m-sm-10 {
    margin: 5rem !important;
  }

  .mt-sm-10,
.my-sm-10 {
    margin-top: 5rem !important;
  }

  .mr-sm-10,
.mx-sm-10 {
    margin-right: 5rem !important;
  }

  .mb-sm-10,
.my-sm-10 {
    margin-bottom: 5rem !important;
  }

  .ml-sm-10,
.mx-sm-10 {
    margin-left: 5rem !important;
  }

  .m-sm-11 {
    margin: 6rem !important;
  }

  .mt-sm-11,
.my-sm-11 {
    margin-top: 6rem !important;
  }

  .mr-sm-11,
.mx-sm-11 {
    margin-right: 6rem !important;
  }

  .mb-sm-11,
.my-sm-11 {
    margin-bottom: 6rem !important;
  }

  .ml-sm-11,
.mx-sm-11 {
    margin-left: 6rem !important;
  }

  .m-sm-12 {
    margin: 8rem !important;
  }

  .mt-sm-12,
.my-sm-12 {
    margin-top: 8rem !important;
  }

  .mr-sm-12,
.mx-sm-12 {
    margin-right: 8rem !important;
  }

  .mb-sm-12,
.my-sm-12 {
    margin-bottom: 8rem !important;
  }

  .ml-sm-12,
.mx-sm-12 {
    margin-left: 8rem !important;
  }

  .m-sm-13 {
    margin: 10rem !important;
  }

  .mt-sm-13,
.my-sm-13 {
    margin-top: 10rem !important;
  }

  .mr-sm-13,
.mx-sm-13 {
    margin-right: 10rem !important;
  }

  .mb-sm-13,
.my-sm-13 {
    margin-bottom: 10rem !important;
  }

  .ml-sm-13,
.mx-sm-13 {
    margin-left: 10rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 0.75rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 0.75rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 0.75rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 0.75rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 0.75rem !important;
  }

  .p-sm-4 {
    padding: 1rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1rem !important;
  }

  .p-sm-5 {
    padding: 1.5rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 1.5rem !important;
  }

  .p-sm-6 {
    padding: 2rem !important;
  }

  .pt-sm-6,
.py-sm-6 {
    padding-top: 2rem !important;
  }

  .pr-sm-6,
.px-sm-6 {
    padding-right: 2rem !important;
  }

  .pb-sm-6,
.py-sm-6 {
    padding-bottom: 2rem !important;
  }

  .pl-sm-6,
.px-sm-6 {
    padding-left: 2rem !important;
  }

  .p-sm-7 {
    padding: 2.5rem !important;
  }

  .pt-sm-7,
.py-sm-7 {
    padding-top: 2.5rem !important;
  }

  .pr-sm-7,
.px-sm-7 {
    padding-right: 2.5rem !important;
  }

  .pb-sm-7,
.py-sm-7 {
    padding-bottom: 2.5rem !important;
  }

  .pl-sm-7,
.px-sm-7 {
    padding-left: 2.5rem !important;
  }

  .p-sm-8 {
    padding: 3rem !important;
  }

  .pt-sm-8,
.py-sm-8 {
    padding-top: 3rem !important;
  }

  .pr-sm-8,
.px-sm-8 {
    padding-right: 3rem !important;
  }

  .pb-sm-8,
.py-sm-8 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-8,
.px-sm-8 {
    padding-left: 3rem !important;
  }

  .p-sm-9 {
    padding: 4rem !important;
  }

  .pt-sm-9,
.py-sm-9 {
    padding-top: 4rem !important;
  }

  .pr-sm-9,
.px-sm-9 {
    padding-right: 4rem !important;
  }

  .pb-sm-9,
.py-sm-9 {
    padding-bottom: 4rem !important;
  }

  .pl-sm-9,
.px-sm-9 {
    padding-left: 4rem !important;
  }

  .p-sm-10 {
    padding: 5rem !important;
  }

  .pt-sm-10,
.py-sm-10 {
    padding-top: 5rem !important;
  }

  .pr-sm-10,
.px-sm-10 {
    padding-right: 5rem !important;
  }

  .pb-sm-10,
.py-sm-10 {
    padding-bottom: 5rem !important;
  }

  .pl-sm-10,
.px-sm-10 {
    padding-left: 5rem !important;
  }

  .p-sm-11 {
    padding: 6rem !important;
  }

  .pt-sm-11,
.py-sm-11 {
    padding-top: 6rem !important;
  }

  .pr-sm-11,
.px-sm-11 {
    padding-right: 6rem !important;
  }

  .pb-sm-11,
.py-sm-11 {
    padding-bottom: 6rem !important;
  }

  .pl-sm-11,
.px-sm-11 {
    padding-left: 6rem !important;
  }

  .p-sm-12 {
    padding: 8rem !important;
  }

  .pt-sm-12,
.py-sm-12 {
    padding-top: 8rem !important;
  }

  .pr-sm-12,
.px-sm-12 {
    padding-right: 8rem !important;
  }

  .pb-sm-12,
.py-sm-12 {
    padding-bottom: 8rem !important;
  }

  .pl-sm-12,
.px-sm-12 {
    padding-left: 8rem !important;
  }

  .p-sm-13 {
    padding: 10rem !important;
  }

  .pt-sm-13,
.py-sm-13 {
    padding-top: 10rem !important;
  }

  .pr-sm-13,
.px-sm-13 {
    padding-right: 10rem !important;
  }

  .pb-sm-13,
.py-sm-13 {
    padding-bottom: 10rem !important;
  }

  .pl-sm-13,
.px-sm-13 {
    padding-left: 10rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -0.75rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -0.75rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -0.75rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -0.75rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -0.75rem !important;
  }

  .m-sm-n4 {
    margin: -1rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1rem !important;
  }

  .m-sm-n5 {
    margin: -1.5rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n6 {
    margin: -2rem !important;
  }

  .mt-sm-n6,
.my-sm-n6 {
    margin-top: -2rem !important;
  }

  .mr-sm-n6,
.mx-sm-n6 {
    margin-right: -2rem !important;
  }

  .mb-sm-n6,
.my-sm-n6 {
    margin-bottom: -2rem !important;
  }

  .ml-sm-n6,
.mx-sm-n6 {
    margin-left: -2rem !important;
  }

  .m-sm-n7 {
    margin: -2.5rem !important;
  }

  .mt-sm-n7,
.my-sm-n7 {
    margin-top: -2.5rem !important;
  }

  .mr-sm-n7,
.mx-sm-n7 {
    margin-right: -2.5rem !important;
  }

  .mb-sm-n7,
.my-sm-n7 {
    margin-bottom: -2.5rem !important;
  }

  .ml-sm-n7,
.mx-sm-n7 {
    margin-left: -2.5rem !important;
  }

  .m-sm-n8 {
    margin: -3rem !important;
  }

  .mt-sm-n8,
.my-sm-n8 {
    margin-top: -3rem !important;
  }

  .mr-sm-n8,
.mx-sm-n8 {
    margin-right: -3rem !important;
  }

  .mb-sm-n8,
.my-sm-n8 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n8,
.mx-sm-n8 {
    margin-left: -3rem !important;
  }

  .m-sm-n9 {
    margin: -4rem !important;
  }

  .mt-sm-n9,
.my-sm-n9 {
    margin-top: -4rem !important;
  }

  .mr-sm-n9,
.mx-sm-n9 {
    margin-right: -4rem !important;
  }

  .mb-sm-n9,
.my-sm-n9 {
    margin-bottom: -4rem !important;
  }

  .ml-sm-n9,
.mx-sm-n9 {
    margin-left: -4rem !important;
  }

  .m-sm-n10 {
    margin: -5rem !important;
  }

  .mt-sm-n10,
.my-sm-n10 {
    margin-top: -5rem !important;
  }

  .mr-sm-n10,
.mx-sm-n10 {
    margin-right: -5rem !important;
  }

  .mb-sm-n10,
.my-sm-n10 {
    margin-bottom: -5rem !important;
  }

  .ml-sm-n10,
.mx-sm-n10 {
    margin-left: -5rem !important;
  }

  .m-sm-n11 {
    margin: -6rem !important;
  }

  .mt-sm-n11,
.my-sm-n11 {
    margin-top: -6rem !important;
  }

  .mr-sm-n11,
.mx-sm-n11 {
    margin-right: -6rem !important;
  }

  .mb-sm-n11,
.my-sm-n11 {
    margin-bottom: -6rem !important;
  }

  .ml-sm-n11,
.mx-sm-n11 {
    margin-left: -6rem !important;
  }

  .m-sm-n12 {
    margin: -8rem !important;
  }

  .mt-sm-n12,
.my-sm-n12 {
    margin-top: -8rem !important;
  }

  .mr-sm-n12,
.mx-sm-n12 {
    margin-right: -8rem !important;
  }

  .mb-sm-n12,
.my-sm-n12 {
    margin-bottom: -8rem !important;
  }

  .ml-sm-n12,
.mx-sm-n12 {
    margin-left: -8rem !important;
  }

  .m-sm-n13 {
    margin: -10rem !important;
  }

  .mt-sm-n13,
.my-sm-n13 {
    margin-top: -10rem !important;
  }

  .mr-sm-n13,
.mx-sm-n13 {
    margin-right: -10rem !important;
  }

  .mb-sm-n13,
.my-sm-n13 {
    margin-bottom: -10rem !important;
  }

  .ml-sm-n13,
.mx-sm-n13 {
    margin-left: -10rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 0.75rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 0.75rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 0.75rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 0.75rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 0.75rem !important;
  }

  .m-md-4 {
    margin: 1rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1rem !important;
  }

  .m-md-5 {
    margin: 1.5rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 1.5rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 1.5rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 1.5rem !important;
  }

  .m-md-6 {
    margin: 2rem !important;
  }

  .mt-md-6,
.my-md-6 {
    margin-top: 2rem !important;
  }

  .mr-md-6,
.mx-md-6 {
    margin-right: 2rem !important;
  }

  .mb-md-6,
.my-md-6 {
    margin-bottom: 2rem !important;
  }

  .ml-md-6,
.mx-md-6 {
    margin-left: 2rem !important;
  }

  .m-md-7 {
    margin: 2.5rem !important;
  }

  .mt-md-7,
.my-md-7 {
    margin-top: 2.5rem !important;
  }

  .mr-md-7,
.mx-md-7 {
    margin-right: 2.5rem !important;
  }

  .mb-md-7,
.my-md-7 {
    margin-bottom: 2.5rem !important;
  }

  .ml-md-7,
.mx-md-7 {
    margin-left: 2.5rem !important;
  }

  .m-md-8 {
    margin: 3rem !important;
  }

  .mt-md-8,
.my-md-8 {
    margin-top: 3rem !important;
  }

  .mr-md-8,
.mx-md-8 {
    margin-right: 3rem !important;
  }

  .mb-md-8,
.my-md-8 {
    margin-bottom: 3rem !important;
  }

  .ml-md-8,
.mx-md-8 {
    margin-left: 3rem !important;
  }

  .m-md-9 {
    margin: 4rem !important;
  }

  .mt-md-9,
.my-md-9 {
    margin-top: 4rem !important;
  }

  .mr-md-9,
.mx-md-9 {
    margin-right: 4rem !important;
  }

  .mb-md-9,
.my-md-9 {
    margin-bottom: 4rem !important;
  }

  .ml-md-9,
.mx-md-9 {
    margin-left: 4rem !important;
  }

  .m-md-10 {
    margin: 5rem !important;
  }

  .mt-md-10,
.my-md-10 {
    margin-top: 5rem !important;
  }

  .mr-md-10,
.mx-md-10 {
    margin-right: 5rem !important;
  }

  .mb-md-10,
.my-md-10 {
    margin-bottom: 5rem !important;
  }

  .ml-md-10,
.mx-md-10 {
    margin-left: 5rem !important;
  }

  .m-md-11 {
    margin: 6rem !important;
  }

  .mt-md-11,
.my-md-11 {
    margin-top: 6rem !important;
  }

  .mr-md-11,
.mx-md-11 {
    margin-right: 6rem !important;
  }

  .mb-md-11,
.my-md-11 {
    margin-bottom: 6rem !important;
  }

  .ml-md-11,
.mx-md-11 {
    margin-left: 6rem !important;
  }

  .m-md-12 {
    margin: 8rem !important;
  }

  .mt-md-12,
.my-md-12 {
    margin-top: 8rem !important;
  }

  .mr-md-12,
.mx-md-12 {
    margin-right: 8rem !important;
  }

  .mb-md-12,
.my-md-12 {
    margin-bottom: 8rem !important;
  }

  .ml-md-12,
.mx-md-12 {
    margin-left: 8rem !important;
  }

  .m-md-13 {
    margin: 10rem !important;
  }

  .mt-md-13,
.my-md-13 {
    margin-top: 10rem !important;
  }

  .mr-md-13,
.mx-md-13 {
    margin-right: 10rem !important;
  }

  .mb-md-13,
.my-md-13 {
    margin-bottom: 10rem !important;
  }

  .ml-md-13,
.mx-md-13 {
    margin-left: 10rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 0.75rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 0.75rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 0.75rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 0.75rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 0.75rem !important;
  }

  .p-md-4 {
    padding: 1rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1rem !important;
  }

  .p-md-5 {
    padding: 1.5rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 1.5rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 1.5rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 1.5rem !important;
  }

  .p-md-6 {
    padding: 2rem !important;
  }

  .pt-md-6,
.py-md-6 {
    padding-top: 2rem !important;
  }

  .pr-md-6,
.px-md-6 {
    padding-right: 2rem !important;
  }

  .pb-md-6,
.py-md-6 {
    padding-bottom: 2rem !important;
  }

  .pl-md-6,
.px-md-6 {
    padding-left: 2rem !important;
  }

  .p-md-7 {
    padding: 2.5rem !important;
  }

  .pt-md-7,
.py-md-7 {
    padding-top: 2.5rem !important;
  }

  .pr-md-7,
.px-md-7 {
    padding-right: 2.5rem !important;
  }

  .pb-md-7,
.py-md-7 {
    padding-bottom: 2.5rem !important;
  }

  .pl-md-7,
.px-md-7 {
    padding-left: 2.5rem !important;
  }

  .p-md-8 {
    padding: 3rem !important;
  }

  .pt-md-8,
.py-md-8 {
    padding-top: 3rem !important;
  }

  .pr-md-8,
.px-md-8 {
    padding-right: 3rem !important;
  }

  .pb-md-8,
.py-md-8 {
    padding-bottom: 3rem !important;
  }

  .pl-md-8,
.px-md-8 {
    padding-left: 3rem !important;
  }

  .p-md-9 {
    padding: 4rem !important;
  }

  .pt-md-9,
.py-md-9 {
    padding-top: 4rem !important;
  }

  .pr-md-9,
.px-md-9 {
    padding-right: 4rem !important;
  }

  .pb-md-9,
.py-md-9 {
    padding-bottom: 4rem !important;
  }

  .pl-md-9,
.px-md-9 {
    padding-left: 4rem !important;
  }

  .p-md-10 {
    padding: 5rem !important;
  }

  .pt-md-10,
.py-md-10 {
    padding-top: 5rem !important;
  }

  .pr-md-10,
.px-md-10 {
    padding-right: 5rem !important;
  }

  .pb-md-10,
.py-md-10 {
    padding-bottom: 5rem !important;
  }

  .pl-md-10,
.px-md-10 {
    padding-left: 5rem !important;
  }

  .p-md-11 {
    padding: 6rem !important;
  }

  .pt-md-11,
.py-md-11 {
    padding-top: 6rem !important;
  }

  .pr-md-11,
.px-md-11 {
    padding-right: 6rem !important;
  }

  .pb-md-11,
.py-md-11 {
    padding-bottom: 6rem !important;
  }

  .pl-md-11,
.px-md-11 {
    padding-left: 6rem !important;
  }

  .p-md-12 {
    padding: 8rem !important;
  }

  .pt-md-12,
.py-md-12 {
    padding-top: 8rem !important;
  }

  .pr-md-12,
.px-md-12 {
    padding-right: 8rem !important;
  }

  .pb-md-12,
.py-md-12 {
    padding-bottom: 8rem !important;
  }

  .pl-md-12,
.px-md-12 {
    padding-left: 8rem !important;
  }

  .p-md-13 {
    padding: 10rem !important;
  }

  .pt-md-13,
.py-md-13 {
    padding-top: 10rem !important;
  }

  .pr-md-13,
.px-md-13 {
    padding-right: 10rem !important;
  }

  .pb-md-13,
.py-md-13 {
    padding-bottom: 10rem !important;
  }

  .pl-md-13,
.px-md-13 {
    padding-left: 10rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -0.75rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -0.75rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -0.75rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -0.75rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -0.75rem !important;
  }

  .m-md-n4 {
    margin: -1rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1rem !important;
  }

  .m-md-n5 {
    margin: -1.5rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -1.5rem !important;
  }

  .m-md-n6 {
    margin: -2rem !important;
  }

  .mt-md-n6,
.my-md-n6 {
    margin-top: -2rem !important;
  }

  .mr-md-n6,
.mx-md-n6 {
    margin-right: -2rem !important;
  }

  .mb-md-n6,
.my-md-n6 {
    margin-bottom: -2rem !important;
  }

  .ml-md-n6,
.mx-md-n6 {
    margin-left: -2rem !important;
  }

  .m-md-n7 {
    margin: -2.5rem !important;
  }

  .mt-md-n7,
.my-md-n7 {
    margin-top: -2.5rem !important;
  }

  .mr-md-n7,
.mx-md-n7 {
    margin-right: -2.5rem !important;
  }

  .mb-md-n7,
.my-md-n7 {
    margin-bottom: -2.5rem !important;
  }

  .ml-md-n7,
.mx-md-n7 {
    margin-left: -2.5rem !important;
  }

  .m-md-n8 {
    margin: -3rem !important;
  }

  .mt-md-n8,
.my-md-n8 {
    margin-top: -3rem !important;
  }

  .mr-md-n8,
.mx-md-n8 {
    margin-right: -3rem !important;
  }

  .mb-md-n8,
.my-md-n8 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n8,
.mx-md-n8 {
    margin-left: -3rem !important;
  }

  .m-md-n9 {
    margin: -4rem !important;
  }

  .mt-md-n9,
.my-md-n9 {
    margin-top: -4rem !important;
  }

  .mr-md-n9,
.mx-md-n9 {
    margin-right: -4rem !important;
  }

  .mb-md-n9,
.my-md-n9 {
    margin-bottom: -4rem !important;
  }

  .ml-md-n9,
.mx-md-n9 {
    margin-left: -4rem !important;
  }

  .m-md-n10 {
    margin: -5rem !important;
  }

  .mt-md-n10,
.my-md-n10 {
    margin-top: -5rem !important;
  }

  .mr-md-n10,
.mx-md-n10 {
    margin-right: -5rem !important;
  }

  .mb-md-n10,
.my-md-n10 {
    margin-bottom: -5rem !important;
  }

  .ml-md-n10,
.mx-md-n10 {
    margin-left: -5rem !important;
  }

  .m-md-n11 {
    margin: -6rem !important;
  }

  .mt-md-n11,
.my-md-n11 {
    margin-top: -6rem !important;
  }

  .mr-md-n11,
.mx-md-n11 {
    margin-right: -6rem !important;
  }

  .mb-md-n11,
.my-md-n11 {
    margin-bottom: -6rem !important;
  }

  .ml-md-n11,
.mx-md-n11 {
    margin-left: -6rem !important;
  }

  .m-md-n12 {
    margin: -8rem !important;
  }

  .mt-md-n12,
.my-md-n12 {
    margin-top: -8rem !important;
  }

  .mr-md-n12,
.mx-md-n12 {
    margin-right: -8rem !important;
  }

  .mb-md-n12,
.my-md-n12 {
    margin-bottom: -8rem !important;
  }

  .ml-md-n12,
.mx-md-n12 {
    margin-left: -8rem !important;
  }

  .m-md-n13 {
    margin: -10rem !important;
  }

  .mt-md-n13,
.my-md-n13 {
    margin-top: -10rem !important;
  }

  .mr-md-n13,
.mx-md-n13 {
    margin-right: -10rem !important;
  }

  .mb-md-n13,
.my-md-n13 {
    margin-bottom: -10rem !important;
  }

  .ml-md-n13,
.mx-md-n13 {
    margin-left: -10rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 0.75rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 0.75rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 0.75rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 0.75rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 0.75rem !important;
  }

  .m-lg-4 {
    margin: 1rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1rem !important;
  }

  .m-lg-5 {
    margin: 1.5rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 1.5rem !important;
  }

  .m-lg-6 {
    margin: 2rem !important;
  }

  .mt-lg-6,
.my-lg-6 {
    margin-top: 2rem !important;
  }

  .mr-lg-6,
.mx-lg-6 {
    margin-right: 2rem !important;
  }

  .mb-lg-6,
.my-lg-6 {
    margin-bottom: 2rem !important;
  }

  .ml-lg-6,
.mx-lg-6 {
    margin-left: 2rem !important;
  }

  .m-lg-7 {
    margin: 2.5rem !important;
  }

  .mt-lg-7,
.my-lg-7 {
    margin-top: 2.5rem !important;
  }

  .mr-lg-7,
.mx-lg-7 {
    margin-right: 2.5rem !important;
  }

  .mb-lg-7,
.my-lg-7 {
    margin-bottom: 2.5rem !important;
  }

  .ml-lg-7,
.mx-lg-7 {
    margin-left: 2.5rem !important;
  }

  .m-lg-8 {
    margin: 3rem !important;
  }

  .mt-lg-8,
.my-lg-8 {
    margin-top: 3rem !important;
  }

  .mr-lg-8,
.mx-lg-8 {
    margin-right: 3rem !important;
  }

  .mb-lg-8,
.my-lg-8 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-8,
.mx-lg-8 {
    margin-left: 3rem !important;
  }

  .m-lg-9 {
    margin: 4rem !important;
  }

  .mt-lg-9,
.my-lg-9 {
    margin-top: 4rem !important;
  }

  .mr-lg-9,
.mx-lg-9 {
    margin-right: 4rem !important;
  }

  .mb-lg-9,
.my-lg-9 {
    margin-bottom: 4rem !important;
  }

  .ml-lg-9,
.mx-lg-9 {
    margin-left: 4rem !important;
  }

  .m-lg-10 {
    margin: 5rem !important;
  }

  .mt-lg-10,
.my-lg-10 {
    margin-top: 5rem !important;
  }

  .mr-lg-10,
.mx-lg-10 {
    margin-right: 5rem !important;
  }

  .mb-lg-10,
.my-lg-10 {
    margin-bottom: 5rem !important;
  }

  .ml-lg-10,
.mx-lg-10 {
    margin-left: 5rem !important;
  }

  .m-lg-11 {
    margin: 6rem !important;
  }

  .mt-lg-11,
.my-lg-11 {
    margin-top: 6rem !important;
  }

  .mr-lg-11,
.mx-lg-11 {
    margin-right: 6rem !important;
  }

  .mb-lg-11,
.my-lg-11 {
    margin-bottom: 6rem !important;
  }

  .ml-lg-11,
.mx-lg-11 {
    margin-left: 6rem !important;
  }

  .m-lg-12 {
    margin: 8rem !important;
  }

  .mt-lg-12,
.my-lg-12 {
    margin-top: 8rem !important;
  }

  .mr-lg-12,
.mx-lg-12 {
    margin-right: 8rem !important;
  }

  .mb-lg-12,
.my-lg-12 {
    margin-bottom: 8rem !important;
  }

  .ml-lg-12,
.mx-lg-12 {
    margin-left: 8rem !important;
  }

  .m-lg-13 {
    margin: 10rem !important;
  }

  .mt-lg-13,
.my-lg-13 {
    margin-top: 10rem !important;
  }

  .mr-lg-13,
.mx-lg-13 {
    margin-right: 10rem !important;
  }

  .mb-lg-13,
.my-lg-13 {
    margin-bottom: 10rem !important;
  }

  .ml-lg-13,
.mx-lg-13 {
    margin-left: 10rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 0.75rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 0.75rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 0.75rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 0.75rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 0.75rem !important;
  }

  .p-lg-4 {
    padding: 1rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1rem !important;
  }

  .p-lg-5 {
    padding: 1.5rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 1.5rem !important;
  }

  .p-lg-6 {
    padding: 2rem !important;
  }

  .pt-lg-6,
.py-lg-6 {
    padding-top: 2rem !important;
  }

  .pr-lg-6,
.px-lg-6 {
    padding-right: 2rem !important;
  }

  .pb-lg-6,
.py-lg-6 {
    padding-bottom: 2rem !important;
  }

  .pl-lg-6,
.px-lg-6 {
    padding-left: 2rem !important;
  }

  .p-lg-7 {
    padding: 2.5rem !important;
  }

  .pt-lg-7,
.py-lg-7 {
    padding-top: 2.5rem !important;
  }

  .pr-lg-7,
.px-lg-7 {
    padding-right: 2.5rem !important;
  }

  .pb-lg-7,
.py-lg-7 {
    padding-bottom: 2.5rem !important;
  }

  .pl-lg-7,
.px-lg-7 {
    padding-left: 2.5rem !important;
  }

  .p-lg-8 {
    padding: 3rem !important;
  }

  .pt-lg-8,
.py-lg-8 {
    padding-top: 3rem !important;
  }

  .pr-lg-8,
.px-lg-8 {
    padding-right: 3rem !important;
  }

  .pb-lg-8,
.py-lg-8 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-8,
.px-lg-8 {
    padding-left: 3rem !important;
  }

  .p-lg-9 {
    padding: 4rem !important;
  }

  .pt-lg-9,
.py-lg-9 {
    padding-top: 4rem !important;
  }

  .pr-lg-9,
.px-lg-9 {
    padding-right: 4rem !important;
  }

  .pb-lg-9,
.py-lg-9 {
    padding-bottom: 4rem !important;
  }

  .pl-lg-9,
.px-lg-9 {
    padding-left: 4rem !important;
  }

  .p-lg-10 {
    padding: 5rem !important;
  }

  .pt-lg-10,
.py-lg-10 {
    padding-top: 5rem !important;
  }

  .pr-lg-10,
.px-lg-10 {
    padding-right: 5rem !important;
  }

  .pb-lg-10,
.py-lg-10 {
    padding-bottom: 5rem !important;
  }

  .pl-lg-10,
.px-lg-10 {
    padding-left: 5rem !important;
  }

  .p-lg-11 {
    padding: 6rem !important;
  }

  .pt-lg-11,
.py-lg-11 {
    padding-top: 6rem !important;
  }

  .pr-lg-11,
.px-lg-11 {
    padding-right: 6rem !important;
  }

  .pb-lg-11,
.py-lg-11 {
    padding-bottom: 6rem !important;
  }

  .pl-lg-11,
.px-lg-11 {
    padding-left: 6rem !important;
  }

  .p-lg-12 {
    padding: 8rem !important;
  }

  .pt-lg-12,
.py-lg-12 {
    padding-top: 8rem !important;
  }

  .pr-lg-12,
.px-lg-12 {
    padding-right: 8rem !important;
  }

  .pb-lg-12,
.py-lg-12 {
    padding-bottom: 8rem !important;
  }

  .pl-lg-12,
.px-lg-12 {
    padding-left: 8rem !important;
  }

  .p-lg-13 {
    padding: 10rem !important;
  }

  .pt-lg-13,
.py-lg-13 {
    padding-top: 10rem !important;
  }

  .pr-lg-13,
.px-lg-13 {
    padding-right: 10rem !important;
  }

  .pb-lg-13,
.py-lg-13 {
    padding-bottom: 10rem !important;
  }

  .pl-lg-13,
.px-lg-13 {
    padding-left: 10rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -0.75rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -0.75rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -0.75rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -0.75rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -0.75rem !important;
  }

  .m-lg-n4 {
    margin: -1rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1rem !important;
  }

  .m-lg-n5 {
    margin: -1.5rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n6 {
    margin: -2rem !important;
  }

  .mt-lg-n6,
.my-lg-n6 {
    margin-top: -2rem !important;
  }

  .mr-lg-n6,
.mx-lg-n6 {
    margin-right: -2rem !important;
  }

  .mb-lg-n6,
.my-lg-n6 {
    margin-bottom: -2rem !important;
  }

  .ml-lg-n6,
.mx-lg-n6 {
    margin-left: -2rem !important;
  }

  .m-lg-n7 {
    margin: -2.5rem !important;
  }

  .mt-lg-n7,
.my-lg-n7 {
    margin-top: -2.5rem !important;
  }

  .mr-lg-n7,
.mx-lg-n7 {
    margin-right: -2.5rem !important;
  }

  .mb-lg-n7,
.my-lg-n7 {
    margin-bottom: -2.5rem !important;
  }

  .ml-lg-n7,
.mx-lg-n7 {
    margin-left: -2.5rem !important;
  }

  .m-lg-n8 {
    margin: -3rem !important;
  }

  .mt-lg-n8,
.my-lg-n8 {
    margin-top: -3rem !important;
  }

  .mr-lg-n8,
.mx-lg-n8 {
    margin-right: -3rem !important;
  }

  .mb-lg-n8,
.my-lg-n8 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n8,
.mx-lg-n8 {
    margin-left: -3rem !important;
  }

  .m-lg-n9 {
    margin: -4rem !important;
  }

  .mt-lg-n9,
.my-lg-n9 {
    margin-top: -4rem !important;
  }

  .mr-lg-n9,
.mx-lg-n9 {
    margin-right: -4rem !important;
  }

  .mb-lg-n9,
.my-lg-n9 {
    margin-bottom: -4rem !important;
  }

  .ml-lg-n9,
.mx-lg-n9 {
    margin-left: -4rem !important;
  }

  .m-lg-n10 {
    margin: -5rem !important;
  }

  .mt-lg-n10,
.my-lg-n10 {
    margin-top: -5rem !important;
  }

  .mr-lg-n10,
.mx-lg-n10 {
    margin-right: -5rem !important;
  }

  .mb-lg-n10,
.my-lg-n10 {
    margin-bottom: -5rem !important;
  }

  .ml-lg-n10,
.mx-lg-n10 {
    margin-left: -5rem !important;
  }

  .m-lg-n11 {
    margin: -6rem !important;
  }

  .mt-lg-n11,
.my-lg-n11 {
    margin-top: -6rem !important;
  }

  .mr-lg-n11,
.mx-lg-n11 {
    margin-right: -6rem !important;
  }

  .mb-lg-n11,
.my-lg-n11 {
    margin-bottom: -6rem !important;
  }

  .ml-lg-n11,
.mx-lg-n11 {
    margin-left: -6rem !important;
  }

  .m-lg-n12 {
    margin: -8rem !important;
  }

  .mt-lg-n12,
.my-lg-n12 {
    margin-top: -8rem !important;
  }

  .mr-lg-n12,
.mx-lg-n12 {
    margin-right: -8rem !important;
  }

  .mb-lg-n12,
.my-lg-n12 {
    margin-bottom: -8rem !important;
  }

  .ml-lg-n12,
.mx-lg-n12 {
    margin-left: -8rem !important;
  }

  .m-lg-n13 {
    margin: -10rem !important;
  }

  .mt-lg-n13,
.my-lg-n13 {
    margin-top: -10rem !important;
  }

  .mr-lg-n13,
.mx-lg-n13 {
    margin-right: -10rem !important;
  }

  .mb-lg-n13,
.my-lg-n13 {
    margin-bottom: -10rem !important;
  }

  .ml-lg-n13,
.mx-lg-n13 {
    margin-left: -10rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 0.75rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 0.75rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 0.75rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 0.75rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 0.75rem !important;
  }

  .m-xl-4 {
    margin: 1rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1rem !important;
  }

  .m-xl-5 {
    margin: 1.5rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 1.5rem !important;
  }

  .m-xl-6 {
    margin: 2rem !important;
  }

  .mt-xl-6,
.my-xl-6 {
    margin-top: 2rem !important;
  }

  .mr-xl-6,
.mx-xl-6 {
    margin-right: 2rem !important;
  }

  .mb-xl-6,
.my-xl-6 {
    margin-bottom: 2rem !important;
  }

  .ml-xl-6,
.mx-xl-6 {
    margin-left: 2rem !important;
  }

  .m-xl-7 {
    margin: 2.5rem !important;
  }

  .mt-xl-7,
.my-xl-7 {
    margin-top: 2.5rem !important;
  }

  .mr-xl-7,
.mx-xl-7 {
    margin-right: 2.5rem !important;
  }

  .mb-xl-7,
.my-xl-7 {
    margin-bottom: 2.5rem !important;
  }

  .ml-xl-7,
.mx-xl-7 {
    margin-left: 2.5rem !important;
  }

  .m-xl-8 {
    margin: 3rem !important;
  }

  .mt-xl-8,
.my-xl-8 {
    margin-top: 3rem !important;
  }

  .mr-xl-8,
.mx-xl-8 {
    margin-right: 3rem !important;
  }

  .mb-xl-8,
.my-xl-8 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-8,
.mx-xl-8 {
    margin-left: 3rem !important;
  }

  .m-xl-9 {
    margin: 4rem !important;
  }

  .mt-xl-9,
.my-xl-9 {
    margin-top: 4rem !important;
  }

  .mr-xl-9,
.mx-xl-9 {
    margin-right: 4rem !important;
  }

  .mb-xl-9,
.my-xl-9 {
    margin-bottom: 4rem !important;
  }

  .ml-xl-9,
.mx-xl-9 {
    margin-left: 4rem !important;
  }

  .m-xl-10 {
    margin: 5rem !important;
  }

  .mt-xl-10,
.my-xl-10 {
    margin-top: 5rem !important;
  }

  .mr-xl-10,
.mx-xl-10 {
    margin-right: 5rem !important;
  }

  .mb-xl-10,
.my-xl-10 {
    margin-bottom: 5rem !important;
  }

  .ml-xl-10,
.mx-xl-10 {
    margin-left: 5rem !important;
  }

  .m-xl-11 {
    margin: 6rem !important;
  }

  .mt-xl-11,
.my-xl-11 {
    margin-top: 6rem !important;
  }

  .mr-xl-11,
.mx-xl-11 {
    margin-right: 6rem !important;
  }

  .mb-xl-11,
.my-xl-11 {
    margin-bottom: 6rem !important;
  }

  .ml-xl-11,
.mx-xl-11 {
    margin-left: 6rem !important;
  }

  .m-xl-12 {
    margin: 8rem !important;
  }

  .mt-xl-12,
.my-xl-12 {
    margin-top: 8rem !important;
  }

  .mr-xl-12,
.mx-xl-12 {
    margin-right: 8rem !important;
  }

  .mb-xl-12,
.my-xl-12 {
    margin-bottom: 8rem !important;
  }

  .ml-xl-12,
.mx-xl-12 {
    margin-left: 8rem !important;
  }

  .m-xl-13 {
    margin: 10rem !important;
  }

  .mt-xl-13,
.my-xl-13 {
    margin-top: 10rem !important;
  }

  .mr-xl-13,
.mx-xl-13 {
    margin-right: 10rem !important;
  }

  .mb-xl-13,
.my-xl-13 {
    margin-bottom: 10rem !important;
  }

  .ml-xl-13,
.mx-xl-13 {
    margin-left: 10rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 0.75rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 0.75rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 0.75rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 0.75rem !important;
  }

  .p-xl-4 {
    padding: 1rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1rem !important;
  }

  .p-xl-5 {
    padding: 1.5rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 1.5rem !important;
  }

  .p-xl-6 {
    padding: 2rem !important;
  }

  .pt-xl-6,
.py-xl-6 {
    padding-top: 2rem !important;
  }

  .pr-xl-6,
.px-xl-6 {
    padding-right: 2rem !important;
  }

  .pb-xl-6,
.py-xl-6 {
    padding-bottom: 2rem !important;
  }

  .pl-xl-6,
.px-xl-6 {
    padding-left: 2rem !important;
  }

  .p-xl-7 {
    padding: 2.5rem !important;
  }

  .pt-xl-7,
.py-xl-7 {
    padding-top: 2.5rem !important;
  }

  .pr-xl-7,
.px-xl-7 {
    padding-right: 2.5rem !important;
  }

  .pb-xl-7,
.py-xl-7 {
    padding-bottom: 2.5rem !important;
  }

  .pl-xl-7,
.px-xl-7 {
    padding-left: 2.5rem !important;
  }

  .p-xl-8 {
    padding: 3rem !important;
  }

  .pt-xl-8,
.py-xl-8 {
    padding-top: 3rem !important;
  }

  .pr-xl-8,
.px-xl-8 {
    padding-right: 3rem !important;
  }

  .pb-xl-8,
.py-xl-8 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-8,
.px-xl-8 {
    padding-left: 3rem !important;
  }

  .p-xl-9 {
    padding: 4rem !important;
  }

  .pt-xl-9,
.py-xl-9 {
    padding-top: 4rem !important;
  }

  .pr-xl-9,
.px-xl-9 {
    padding-right: 4rem !important;
  }

  .pb-xl-9,
.py-xl-9 {
    padding-bottom: 4rem !important;
  }

  .pl-xl-9,
.px-xl-9 {
    padding-left: 4rem !important;
  }

  .p-xl-10 {
    padding: 5rem !important;
  }

  .pt-xl-10,
.py-xl-10 {
    padding-top: 5rem !important;
  }

  .pr-xl-10,
.px-xl-10 {
    padding-right: 5rem !important;
  }

  .pb-xl-10,
.py-xl-10 {
    padding-bottom: 5rem !important;
  }

  .pl-xl-10,
.px-xl-10 {
    padding-left: 5rem !important;
  }

  .p-xl-11 {
    padding: 6rem !important;
  }

  .pt-xl-11,
.py-xl-11 {
    padding-top: 6rem !important;
  }

  .pr-xl-11,
.px-xl-11 {
    padding-right: 6rem !important;
  }

  .pb-xl-11,
.py-xl-11 {
    padding-bottom: 6rem !important;
  }

  .pl-xl-11,
.px-xl-11 {
    padding-left: 6rem !important;
  }

  .p-xl-12 {
    padding: 8rem !important;
  }

  .pt-xl-12,
.py-xl-12 {
    padding-top: 8rem !important;
  }

  .pr-xl-12,
.px-xl-12 {
    padding-right: 8rem !important;
  }

  .pb-xl-12,
.py-xl-12 {
    padding-bottom: 8rem !important;
  }

  .pl-xl-12,
.px-xl-12 {
    padding-left: 8rem !important;
  }

  .p-xl-13 {
    padding: 10rem !important;
  }

  .pt-xl-13,
.py-xl-13 {
    padding-top: 10rem !important;
  }

  .pr-xl-13,
.px-xl-13 {
    padding-right: 10rem !important;
  }

  .pb-xl-13,
.py-xl-13 {
    padding-bottom: 10rem !important;
  }

  .pl-xl-13,
.px-xl-13 {
    padding-left: 10rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -0.75rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -0.75rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -0.75rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -0.75rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -0.75rem !important;
  }

  .m-xl-n4 {
    margin: -1rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1rem !important;
  }

  .m-xl-n5 {
    margin: -1.5rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n6 {
    margin: -2rem !important;
  }

  .mt-xl-n6,
.my-xl-n6 {
    margin-top: -2rem !important;
  }

  .mr-xl-n6,
.mx-xl-n6 {
    margin-right: -2rem !important;
  }

  .mb-xl-n6,
.my-xl-n6 {
    margin-bottom: -2rem !important;
  }

  .ml-xl-n6,
.mx-xl-n6 {
    margin-left: -2rem !important;
  }

  .m-xl-n7 {
    margin: -2.5rem !important;
  }

  .mt-xl-n7,
.my-xl-n7 {
    margin-top: -2.5rem !important;
  }

  .mr-xl-n7,
.mx-xl-n7 {
    margin-right: -2.5rem !important;
  }

  .mb-xl-n7,
.my-xl-n7 {
    margin-bottom: -2.5rem !important;
  }

  .ml-xl-n7,
.mx-xl-n7 {
    margin-left: -2.5rem !important;
  }

  .m-xl-n8 {
    margin: -3rem !important;
  }

  .mt-xl-n8,
.my-xl-n8 {
    margin-top: -3rem !important;
  }

  .mr-xl-n8,
.mx-xl-n8 {
    margin-right: -3rem !important;
  }

  .mb-xl-n8,
.my-xl-n8 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n8,
.mx-xl-n8 {
    margin-left: -3rem !important;
  }

  .m-xl-n9 {
    margin: -4rem !important;
  }

  .mt-xl-n9,
.my-xl-n9 {
    margin-top: -4rem !important;
  }

  .mr-xl-n9,
.mx-xl-n9 {
    margin-right: -4rem !important;
  }

  .mb-xl-n9,
.my-xl-n9 {
    margin-bottom: -4rem !important;
  }

  .ml-xl-n9,
.mx-xl-n9 {
    margin-left: -4rem !important;
  }

  .m-xl-n10 {
    margin: -5rem !important;
  }

  .mt-xl-n10,
.my-xl-n10 {
    margin-top: -5rem !important;
  }

  .mr-xl-n10,
.mx-xl-n10 {
    margin-right: -5rem !important;
  }

  .mb-xl-n10,
.my-xl-n10 {
    margin-bottom: -5rem !important;
  }

  .ml-xl-n10,
.mx-xl-n10 {
    margin-left: -5rem !important;
  }

  .m-xl-n11 {
    margin: -6rem !important;
  }

  .mt-xl-n11,
.my-xl-n11 {
    margin-top: -6rem !important;
  }

  .mr-xl-n11,
.mx-xl-n11 {
    margin-right: -6rem !important;
  }

  .mb-xl-n11,
.my-xl-n11 {
    margin-bottom: -6rem !important;
  }

  .ml-xl-n11,
.mx-xl-n11 {
    margin-left: -6rem !important;
  }

  .m-xl-n12 {
    margin: -8rem !important;
  }

  .mt-xl-n12,
.my-xl-n12 {
    margin-top: -8rem !important;
  }

  .mr-xl-n12,
.mx-xl-n12 {
    margin-right: -8rem !important;
  }

  .mb-xl-n12,
.my-xl-n12 {
    margin-bottom: -8rem !important;
  }

  .ml-xl-n12,
.mx-xl-n12 {
    margin-left: -8rem !important;
  }

  .m-xl-n13 {
    margin: -10rem !important;
  }

  .mt-xl-n13,
.my-xl-n13 {
    margin-top: -10rem !important;
  }

  .mr-xl-n13,
.mx-xl-n13 {
    margin-right: -10rem !important;
  }

  .mb-xl-n13,
.my-xl-n13 {
    margin-bottom: -10rem !important;
  }

  .ml-xl-n13,
.mx-xl-n13 {
    margin-left: -10rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1340px) {
  .m-xxl-0 {
    margin: 0 !important;
  }

  .mt-xxl-0,
.my-xxl-0 {
    margin-top: 0 !important;
  }

  .mr-xxl-0,
.mx-xxl-0 {
    margin-right: 0 !important;
  }

  .mb-xxl-0,
.my-xxl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xxl-0,
.mx-xxl-0 {
    margin-left: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .mt-xxl-1,
.my-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xxl-1,
.mx-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xxl-1,
.my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xxl-1,
.mx-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .mt-xxl-2,
.my-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xxl-2,
.mx-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xxl-2,
.my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xxl-2,
.mx-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 0.75rem !important;
  }

  .mt-xxl-3,
.my-xxl-3 {
    margin-top: 0.75rem !important;
  }

  .mr-xxl-3,
.mx-xxl-3 {
    margin-right: 0.75rem !important;
  }

  .mb-xxl-3,
.my-xxl-3 {
    margin-bottom: 0.75rem !important;
  }

  .ml-xxl-3,
.mx-xxl-3 {
    margin-left: 0.75rem !important;
  }

  .m-xxl-4 {
    margin: 1rem !important;
  }

  .mt-xxl-4,
.my-xxl-4 {
    margin-top: 1rem !important;
  }

  .mr-xxl-4,
.mx-xxl-4 {
    margin-right: 1rem !important;
  }

  .mb-xxl-4,
.my-xxl-4 {
    margin-bottom: 1rem !important;
  }

  .ml-xxl-4,
.mx-xxl-4 {
    margin-left: 1rem !important;
  }

  .m-xxl-5 {
    margin: 1.5rem !important;
  }

  .mt-xxl-5,
.my-xxl-5 {
    margin-top: 1.5rem !important;
  }

  .mr-xxl-5,
.mx-xxl-5 {
    margin-right: 1.5rem !important;
  }

  .mb-xxl-5,
.my-xxl-5 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xxl-5,
.mx-xxl-5 {
    margin-left: 1.5rem !important;
  }

  .m-xxl-6 {
    margin: 2rem !important;
  }

  .mt-xxl-6,
.my-xxl-6 {
    margin-top: 2rem !important;
  }

  .mr-xxl-6,
.mx-xxl-6 {
    margin-right: 2rem !important;
  }

  .mb-xxl-6,
.my-xxl-6 {
    margin-bottom: 2rem !important;
  }

  .ml-xxl-6,
.mx-xxl-6 {
    margin-left: 2rem !important;
  }

  .m-xxl-7 {
    margin: 2.5rem !important;
  }

  .mt-xxl-7,
.my-xxl-7 {
    margin-top: 2.5rem !important;
  }

  .mr-xxl-7,
.mx-xxl-7 {
    margin-right: 2.5rem !important;
  }

  .mb-xxl-7,
.my-xxl-7 {
    margin-bottom: 2.5rem !important;
  }

  .ml-xxl-7,
.mx-xxl-7 {
    margin-left: 2.5rem !important;
  }

  .m-xxl-8 {
    margin: 3rem !important;
  }

  .mt-xxl-8,
.my-xxl-8 {
    margin-top: 3rem !important;
  }

  .mr-xxl-8,
.mx-xxl-8 {
    margin-right: 3rem !important;
  }

  .mb-xxl-8,
.my-xxl-8 {
    margin-bottom: 3rem !important;
  }

  .ml-xxl-8,
.mx-xxl-8 {
    margin-left: 3rem !important;
  }

  .m-xxl-9 {
    margin: 4rem !important;
  }

  .mt-xxl-9,
.my-xxl-9 {
    margin-top: 4rem !important;
  }

  .mr-xxl-9,
.mx-xxl-9 {
    margin-right: 4rem !important;
  }

  .mb-xxl-9,
.my-xxl-9 {
    margin-bottom: 4rem !important;
  }

  .ml-xxl-9,
.mx-xxl-9 {
    margin-left: 4rem !important;
  }

  .m-xxl-10 {
    margin: 5rem !important;
  }

  .mt-xxl-10,
.my-xxl-10 {
    margin-top: 5rem !important;
  }

  .mr-xxl-10,
.mx-xxl-10 {
    margin-right: 5rem !important;
  }

  .mb-xxl-10,
.my-xxl-10 {
    margin-bottom: 5rem !important;
  }

  .ml-xxl-10,
.mx-xxl-10 {
    margin-left: 5rem !important;
  }

  .m-xxl-11 {
    margin: 6rem !important;
  }

  .mt-xxl-11,
.my-xxl-11 {
    margin-top: 6rem !important;
  }

  .mr-xxl-11,
.mx-xxl-11 {
    margin-right: 6rem !important;
  }

  .mb-xxl-11,
.my-xxl-11 {
    margin-bottom: 6rem !important;
  }

  .ml-xxl-11,
.mx-xxl-11 {
    margin-left: 6rem !important;
  }

  .m-xxl-12 {
    margin: 8rem !important;
  }

  .mt-xxl-12,
.my-xxl-12 {
    margin-top: 8rem !important;
  }

  .mr-xxl-12,
.mx-xxl-12 {
    margin-right: 8rem !important;
  }

  .mb-xxl-12,
.my-xxl-12 {
    margin-bottom: 8rem !important;
  }

  .ml-xxl-12,
.mx-xxl-12 {
    margin-left: 8rem !important;
  }

  .m-xxl-13 {
    margin: 10rem !important;
  }

  .mt-xxl-13,
.my-xxl-13 {
    margin-top: 10rem !important;
  }

  .mr-xxl-13,
.mx-xxl-13 {
    margin-right: 10rem !important;
  }

  .mb-xxl-13,
.my-xxl-13 {
    margin-bottom: 10rem !important;
  }

  .ml-xxl-13,
.mx-xxl-13 {
    margin-left: 10rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .pt-xxl-0,
.py-xxl-0 {
    padding-top: 0 !important;
  }

  .pr-xxl-0,
.px-xxl-0 {
    padding-right: 0 !important;
  }

  .pb-xxl-0,
.py-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xxl-0,
.px-xxl-0 {
    padding-left: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .pt-xxl-1,
.py-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xxl-1,
.px-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xxl-1,
.py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xxl-1,
.px-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .pt-xxl-2,
.py-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xxl-2,
.px-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xxl-2,
.py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xxl-2,
.px-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 0.75rem !important;
  }

  .pt-xxl-3,
.py-xxl-3 {
    padding-top: 0.75rem !important;
  }

  .pr-xxl-3,
.px-xxl-3 {
    padding-right: 0.75rem !important;
  }

  .pb-xxl-3,
.py-xxl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pl-xxl-3,
.px-xxl-3 {
    padding-left: 0.75rem !important;
  }

  .p-xxl-4 {
    padding: 1rem !important;
  }

  .pt-xxl-4,
.py-xxl-4 {
    padding-top: 1rem !important;
  }

  .pr-xxl-4,
.px-xxl-4 {
    padding-right: 1rem !important;
  }

  .pb-xxl-4,
.py-xxl-4 {
    padding-bottom: 1rem !important;
  }

  .pl-xxl-4,
.px-xxl-4 {
    padding-left: 1rem !important;
  }

  .p-xxl-5 {
    padding: 1.5rem !important;
  }

  .pt-xxl-5,
.py-xxl-5 {
    padding-top: 1.5rem !important;
  }

  .pr-xxl-5,
.px-xxl-5 {
    padding-right: 1.5rem !important;
  }

  .pb-xxl-5,
.py-xxl-5 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xxl-5,
.px-xxl-5 {
    padding-left: 1.5rem !important;
  }

  .p-xxl-6 {
    padding: 2rem !important;
  }

  .pt-xxl-6,
.py-xxl-6 {
    padding-top: 2rem !important;
  }

  .pr-xxl-6,
.px-xxl-6 {
    padding-right: 2rem !important;
  }

  .pb-xxl-6,
.py-xxl-6 {
    padding-bottom: 2rem !important;
  }

  .pl-xxl-6,
.px-xxl-6 {
    padding-left: 2rem !important;
  }

  .p-xxl-7 {
    padding: 2.5rem !important;
  }

  .pt-xxl-7,
.py-xxl-7 {
    padding-top: 2.5rem !important;
  }

  .pr-xxl-7,
.px-xxl-7 {
    padding-right: 2.5rem !important;
  }

  .pb-xxl-7,
.py-xxl-7 {
    padding-bottom: 2.5rem !important;
  }

  .pl-xxl-7,
.px-xxl-7 {
    padding-left: 2.5rem !important;
  }

  .p-xxl-8 {
    padding: 3rem !important;
  }

  .pt-xxl-8,
.py-xxl-8 {
    padding-top: 3rem !important;
  }

  .pr-xxl-8,
.px-xxl-8 {
    padding-right: 3rem !important;
  }

  .pb-xxl-8,
.py-xxl-8 {
    padding-bottom: 3rem !important;
  }

  .pl-xxl-8,
.px-xxl-8 {
    padding-left: 3rem !important;
  }

  .p-xxl-9 {
    padding: 4rem !important;
  }

  .pt-xxl-9,
.py-xxl-9 {
    padding-top: 4rem !important;
  }

  .pr-xxl-9,
.px-xxl-9 {
    padding-right: 4rem !important;
  }

  .pb-xxl-9,
.py-xxl-9 {
    padding-bottom: 4rem !important;
  }

  .pl-xxl-9,
.px-xxl-9 {
    padding-left: 4rem !important;
  }

  .p-xxl-10 {
    padding: 5rem !important;
  }

  .pt-xxl-10,
.py-xxl-10 {
    padding-top: 5rem !important;
  }

  .pr-xxl-10,
.px-xxl-10 {
    padding-right: 5rem !important;
  }

  .pb-xxl-10,
.py-xxl-10 {
    padding-bottom: 5rem !important;
  }

  .pl-xxl-10,
.px-xxl-10 {
    padding-left: 5rem !important;
  }

  .p-xxl-11 {
    padding: 6rem !important;
  }

  .pt-xxl-11,
.py-xxl-11 {
    padding-top: 6rem !important;
  }

  .pr-xxl-11,
.px-xxl-11 {
    padding-right: 6rem !important;
  }

  .pb-xxl-11,
.py-xxl-11 {
    padding-bottom: 6rem !important;
  }

  .pl-xxl-11,
.px-xxl-11 {
    padding-left: 6rem !important;
  }

  .p-xxl-12 {
    padding: 8rem !important;
  }

  .pt-xxl-12,
.py-xxl-12 {
    padding-top: 8rem !important;
  }

  .pr-xxl-12,
.px-xxl-12 {
    padding-right: 8rem !important;
  }

  .pb-xxl-12,
.py-xxl-12 {
    padding-bottom: 8rem !important;
  }

  .pl-xxl-12,
.px-xxl-12 {
    padding-left: 8rem !important;
  }

  .p-xxl-13 {
    padding: 10rem !important;
  }

  .pt-xxl-13,
.py-xxl-13 {
    padding-top: 10rem !important;
  }

  .pr-xxl-13,
.px-xxl-13 {
    padding-right: 10rem !important;
  }

  .pb-xxl-13,
.py-xxl-13 {
    padding-bottom: 10rem !important;
  }

  .pl-xxl-13,
.px-xxl-13 {
    padding-left: 10rem !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xxl-n1,
.my-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xxl-n1,
.mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xxl-n1,
.my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xxl-n1,
.mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xxl-n2,
.my-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xxl-n2,
.mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xxl-n2,
.my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xxl-n2,
.mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -0.75rem !important;
  }

  .mt-xxl-n3,
.my-xxl-n3 {
    margin-top: -0.75rem !important;
  }

  .mr-xxl-n3,
.mx-xxl-n3 {
    margin-right: -0.75rem !important;
  }

  .mb-xxl-n3,
.my-xxl-n3 {
    margin-bottom: -0.75rem !important;
  }

  .ml-xxl-n3,
.mx-xxl-n3 {
    margin-left: -0.75rem !important;
  }

  .m-xxl-n4 {
    margin: -1rem !important;
  }

  .mt-xxl-n4,
.my-xxl-n4 {
    margin-top: -1rem !important;
  }

  .mr-xxl-n4,
.mx-xxl-n4 {
    margin-right: -1rem !important;
  }

  .mb-xxl-n4,
.my-xxl-n4 {
    margin-bottom: -1rem !important;
  }

  .ml-xxl-n4,
.mx-xxl-n4 {
    margin-left: -1rem !important;
  }

  .m-xxl-n5 {
    margin: -1.5rem !important;
  }

  .mt-xxl-n5,
.my-xxl-n5 {
    margin-top: -1.5rem !important;
  }

  .mr-xxl-n5,
.mx-xxl-n5 {
    margin-right: -1.5rem !important;
  }

  .mb-xxl-n5,
.my-xxl-n5 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xxl-n5,
.mx-xxl-n5 {
    margin-left: -1.5rem !important;
  }

  .m-xxl-n6 {
    margin: -2rem !important;
  }

  .mt-xxl-n6,
.my-xxl-n6 {
    margin-top: -2rem !important;
  }

  .mr-xxl-n6,
.mx-xxl-n6 {
    margin-right: -2rem !important;
  }

  .mb-xxl-n6,
.my-xxl-n6 {
    margin-bottom: -2rem !important;
  }

  .ml-xxl-n6,
.mx-xxl-n6 {
    margin-left: -2rem !important;
  }

  .m-xxl-n7 {
    margin: -2.5rem !important;
  }

  .mt-xxl-n7,
.my-xxl-n7 {
    margin-top: -2.5rem !important;
  }

  .mr-xxl-n7,
.mx-xxl-n7 {
    margin-right: -2.5rem !important;
  }

  .mb-xxl-n7,
.my-xxl-n7 {
    margin-bottom: -2.5rem !important;
  }

  .ml-xxl-n7,
.mx-xxl-n7 {
    margin-left: -2.5rem !important;
  }

  .m-xxl-n8 {
    margin: -3rem !important;
  }

  .mt-xxl-n8,
.my-xxl-n8 {
    margin-top: -3rem !important;
  }

  .mr-xxl-n8,
.mx-xxl-n8 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n8,
.my-xxl-n8 {
    margin-bottom: -3rem !important;
  }

  .ml-xxl-n8,
.mx-xxl-n8 {
    margin-left: -3rem !important;
  }

  .m-xxl-n9 {
    margin: -4rem !important;
  }

  .mt-xxl-n9,
.my-xxl-n9 {
    margin-top: -4rem !important;
  }

  .mr-xxl-n9,
.mx-xxl-n9 {
    margin-right: -4rem !important;
  }

  .mb-xxl-n9,
.my-xxl-n9 {
    margin-bottom: -4rem !important;
  }

  .ml-xxl-n9,
.mx-xxl-n9 {
    margin-left: -4rem !important;
  }

  .m-xxl-n10 {
    margin: -5rem !important;
  }

  .mt-xxl-n10,
.my-xxl-n10 {
    margin-top: -5rem !important;
  }

  .mr-xxl-n10,
.mx-xxl-n10 {
    margin-right: -5rem !important;
  }

  .mb-xxl-n10,
.my-xxl-n10 {
    margin-bottom: -5rem !important;
  }

  .ml-xxl-n10,
.mx-xxl-n10 {
    margin-left: -5rem !important;
  }

  .m-xxl-n11 {
    margin: -6rem !important;
  }

  .mt-xxl-n11,
.my-xxl-n11 {
    margin-top: -6rem !important;
  }

  .mr-xxl-n11,
.mx-xxl-n11 {
    margin-right: -6rem !important;
  }

  .mb-xxl-n11,
.my-xxl-n11 {
    margin-bottom: -6rem !important;
  }

  .ml-xxl-n11,
.mx-xxl-n11 {
    margin-left: -6rem !important;
  }

  .m-xxl-n12 {
    margin: -8rem !important;
  }

  .mt-xxl-n12,
.my-xxl-n12 {
    margin-top: -8rem !important;
  }

  .mr-xxl-n12,
.mx-xxl-n12 {
    margin-right: -8rem !important;
  }

  .mb-xxl-n12,
.my-xxl-n12 {
    margin-bottom: -8rem !important;
  }

  .ml-xxl-n12,
.mx-xxl-n12 {
    margin-left: -8rem !important;
  }

  .m-xxl-n13 {
    margin: -10rem !important;
  }

  .mt-xxl-n13,
.my-xxl-n13 {
    margin-top: -10rem !important;
  }

  .mr-xxl-n13,
.mx-xxl-n13 {
    margin-right: -10rem !important;
  }

  .mb-xxl-n13,
.my-xxl-n13 {
    margin-bottom: -10rem !important;
  }

  .ml-xxl-n13,
.mx-xxl-n13 {
    margin-left: -10rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mt-xxl-auto,
.my-xxl-auto {
    margin-top: auto !important;
  }

  .mr-xxl-auto,
.mx-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-auto,
.my-xxl-auto {
    margin-bottom: auto !important;
  }

  .ml-xxl-auto,
.mx-xxl-auto {
    margin-left: auto !important;
  }
}
.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1340px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1340px) {
  .d-xxl-none {
    display: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1340px) {
  .text-xxl-left {
    text-align: left !important;
  }

  .text-xxl-right {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
a {
  text-decoration: none;
  color: #8d8e8e;
}

.link {
  font: 0.75rem "Montserrat", "Roboto", Arial, sans-serif;
  line-height: 2;
  font-weight: 500;
  letter-spacing: 0.051875rem;
  text-decoration: none;
}
.link--block {
  display: block;
}
.link--strong {
  font: 0.75rem "Montserrat", "Roboto", Arial, sans-serif;
  line-height: 2;
  font-weight: 700;
  letter-spacing: 0.0625rem;
  color: #1b1d1d;
}
.link--highlighted {
  font: 0.75rem "Montserrat", "Roboto", Arial, sans-serif;
  line-height: 2;
  font-weight: 700;
  letter-spacing: 0.0625rem;
  color: #33d3be;
}
.link--big {
  font: 0.875rem "Montserrat", "Roboto", Arial, sans-serif;
  font-weight: 700;
  line-height: 1.714;
}
.link--branding {
  color: #33d3be;
}
.link--branding-dark {
  color: #00a791;
}
.link--cramped {
  letter-spacing: 0;
}
.link--grey {
  color: #8d8e8e;
}
.link--black {
  color: #1b1d1d;
}
.link--black:focus, .link--black:hover {
  color: #545656 !important;
}
.link--white {
  color: #ffffff;
}
.link--white:focus, .link--white:hover {
  color: rgba(255, 255, 255, 0.8) !important;
}
.link--underline {
  text-decoration: underline;
}
.link--biger-font {
  font-size: 0.875rem;
}
.link:focus, .link:hover {
  color: #00a791;
}

.btn {
  display: inline-block;
  font: 0.875rem "Montserrat", "Roboto", Arial, sans-serif;
  line-height: 1.15;
  font-weight: 700;
  letter-spacing: 0.0625rem;
  padding: 15px 23px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid transparent;
}
@media (max-width: 991.98px) {
  .btn {
    font: 0.75rem "Montserrat", "Roboto", Arial, sans-serif;
    line-height: 1.3333;
    font-weight: 700;
    letter-spacing: 0.0625rem;
    padding: 11px 20px;
  }
}
.btn[disabled] {
  cursor: not-allowed;
}
.btn--primary {
  background: linear-gradient(45deg, #22c7d0, #46dfaa, #46dfaa, #22c7d0);
  color: #ffffff;
  background-size: 300% 100%;
  transition: background-position ease 0.5s, box-shadow ease 0.3s;
}
.btn--primary:focus, .btn--primary:hover {
  color: #ffffff;
  box-shadow: 0 7px 8px -3px rgba(29, 29, 27, 0.12), 0 12px 12px -6px rgba(29, 29, 27, 0.12);
  background-position: 100% 100%;
}
.btn--white-and-black {
  background: #ffffff;
  color: #1b1d1d;
  transition: color ease 0.3s, background-color ease 0.3s, box-shadow ease 0.3s;
  border: 1px solid black;
}
.btn--white-and-black:focus, .btn--white-and-black:hover {
  color: #ffffff;
  background: #1b1d1d;
  box-shadow: 0 7px 8px -3px rgba(29, 29, 27, 0.12), 0 12px 12px -6px rgba(29, 29, 27, 0.12);
}
.btn--black-and-white {
  background: #1b1d1d;
  color: #ffffff;
  transition: color ease 0.3s, background-color ease 0.3s, box-shadow ease 0.3s;
  border: 1px solid black;
}
.btn--black-and-white:focus, .btn--black-and-white:hover {
  color: #1b1d1d;
  background: #ffffff;
  box-shadow: 0 7px 8px -3px rgba(29, 29, 27, 0.12), 0 12px 12px -6px rgba(29, 29, 27, 0.12);
}
.btn--white {
  background: #ffffff;
  color: #1b1d1d;
  border-top: 1px solid transparent;
  transition: background-color ease 0.3s, box-shadow ease 0.3s;
}
.btn--white:focus, .btn--white:hover {
  background: #f2f2f2;
}
.btn--warning {
  background: #e47a04;
  color: #ffffff;
}
.btn--big {
  padding: 19px 47px;
}
@media (max-width: 767.98px) {
  .btn--sm-small {
    padding: 15px 23px;
  }
}
.btn--link {
  color: #8d8e8e;
  padding: 8px 16px;
}
.btn--link:hover, .btn--link:focus {
  color: #1b1d1d;
}

.main-header {
  font: 2.75rem "Montserrat", "Roboto", Arial, sans-serif;
  line-height: 1.27;
  font-weight: 700;
  letter-spacing: -0.025rem;
  color: #1b1d1d;
}
.main-header--big {
  line-height: 1.45;
}

.secondary-header {
  font: 2.5rem "Montserrat", "Roboto", Arial, sans-serif;
  line-height: 1.3;
  font-weight: 700;
  color: #1b1d1d;
}
.secondary-header--white {
  color: #ffffff;
}
@media (max-width: 767.98px) {
  .secondary-header--sm-small {
    font-size: 32px;
  }
}

.paragraph {
  font: 1rem "Montserrat", "Roboto", Arial, sans-serif;
  line-height: 1.7;
}
.paragraph--big {
  font: 1.125rem "Montserrat", "Roboto", Arial, sans-serif;
  line-height: 2;
  font-weight: 500;
}
.paragraph--small {
  font: 0.875rem "Montserrat", "Roboto", Arial, sans-serif;
  line-height: 1.7;
  font-weight: 500;
}
.paragraph--white {
  color: #ffffff;
}
.paragraph--line-height-2 {
  line-height: 2;
}
.paragraph--grey {
  color: #757676;
}
.paragraph--black-75 {
  color: #545656;
}
.paragraph--bold {
  font-weight: 700;
}

.section-header {
  font: 1.75rem "Montserrat", "Roboto", Arial, sans-serif;
  line-height: 2.5rem;
  font-weight: 700;
}
.section-header--white {
  color: #ffffff;
}
.section-header--medium {
  font: 1.5rem "Montserrat", "Roboto", Arial, sans-serif;
  line-height: 2rem;
  font-weight: 700;
}
.section-header--normal-weight {
  font-weight: 500;
}

.sub-section-header {
  font: 0.875rem "Montserrat", "Roboto", Arial, sans-serif;
  line-height: 1rem;
  font-weight: 700;
}

.small-section-header {
  font: 1.25rem "Montserrat", "Roboto", Arial, sans-serif;
  line-height: 1.5rem;
  font-weight: 500;
}
.small-section-header--bold {
  font-weight: 700;
}
.small-section-header--black {
  color: #1b1d1d;
}

.asset-link {
  font: 0.875rem "Montserrat", "Roboto", Arial, sans-serif;
  line-height: 1.7;
  font-weight: 500;
  display: flex;
  width: 100%;
  padding: 16px 24px;
  background: #f8f8f8;
  border-radius: 8px;
}
.asset-link:hover, .asset-link:focus {
  background: #ffffff;
  box-shadow: 0 8px 20px 0 rgba(27, 29, 29, 0.1);
}
.asset-link__description {
  flex-grow: 1;
}
.asset-link__info {
  margin-left: 20px;
  flex-shrink: 0;
  color: #33d3be;
  align-self: center;
}
.asset-link__extension {
  font-weight: 700;
}

.icon-size-1 {
  height: 12px !important;
  font-size: 12px !important;
  width: 12px !important;
}

.icon-size-2 {
  height: 14px !important;
  font-size: 14px !important;
  width: 14px !important;
}

.icon-size-3 {
  height: 16px !important;
  font-size: 16px !important;
  width: 16px !important;
}

.icon-size-4 {
  height: 18px !important;
  font-size: 18px !important;
  width: 18px !important;
}

.icon-size-5 {
  height: 20px !important;
  font-size: 20px !important;
  width: 20px !important;
}

.icon-size-6 {
  height: 22px !important;
  font-size: 22px !important;
  width: 22px !important;
}

.icon-size-7 {
  height: 24px !important;
  font-size: 24px !important;
  width: 24px !important;
}

.icon-size-8 {
  height: 26px !important;
  font-size: 26px !important;
  width: 26px !important;
}

.icon-size-9 {
  height: 28px !important;
  font-size: 28px !important;
  width: 28px !important;
}

.icon-size-10 {
  height: 30px !important;
  font-size: 30px !important;
  width: 30px !important;
}

.list-item {
  position: relative;
  padding-left: 30px;
}
.list-item:before {
  content: "•";
  position: absolute;
  left: 10px;
  font-size: 10px;
  top: 4px;
}

.small-title {
  font: 1.125rem "Montserrat", "Roboto", Arial, sans-serif;
  line-height: 1;
  font-weight: 700;
}

.button-icon-arrow {
  font: 0.875rem "Montserrat", "Roboto", Arial, sans-serif;
  line-height: 1.15;
  font-weight: 700;
  letter-spacing: 0.0625rem;
  width: 100%;
  padding: 19px 16px;
  border-radius: 8px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  max-width: 100%;
  color: #545656;
  padding-right: 16px;
  text-align: left;
  overflow: auto;
}
@media (min-width: 768px) {
  .button-icon-arrow {
    width: 408px;
  }
}
.button-icon-arrow:before {
  width: 24px;
  height: 24px;
  position: relative;
  flex-shrink: 0;
  margin-right: 24px;
}
@media (max-width: 575.98px) {
  .button-icon-arrow:before {
    margin-right: 10px;
  }
}
.button-icon-arrow--bank-account::before {
  background-image: url("/assets/icons/bank-account.svg");
  content: "";
  display: block;
}
.button-icon-arrow--credit-card::before {
  background-image: url("/assets/icons/credit-card.svg");
  content: "";
  display: block;
}
.button-icon-arrow::after {
  content: "";
  display: block;
  position: relative;
  background-image: url("/assets/icons/32px_arrow-right.svg");
  width: 25px;
  height: 25px;
  margin-left: auto;
  flex-shrink: 0;
}
.button-icon-arrow:focus, .button-icon-arrow:hover {
  background-color: #ffffff;
  cursor: pointer;
  box-shadow: 0 7px 8px -3px rgba(29, 29, 27, 0.12), 0 12px 12px -6px rgba(29, 29, 27, 0.12);
}

.button-arrow {
  font: 0.875rem "Montserrat", "Roboto", Arial, sans-serif;
  line-height: 1.15;
  font-weight: 700;
  letter-spacing: 0.0625rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  text-align: left;
  color: #33d3be;
}
.button-arrow:focus, .button-arrow:hover {
  color: #00a791;
}
.button-arrow--left:before {
  width: 24px;
  height: 24px;
  position: relative;
  flex-shrink: 0;
  margin-right: 8px;
  background-image: url("/assets/icons/32px_arrow-left.svg");
  content: "";
  display: block;
}
@media (max-width: 575.98px) {
  .button-arrow--left:before {
    margin-right: 8px;
  }
}
.button-arrow--left:hover::before {
  filter: invert(51%) sepia(46%) saturate(6916%) hue-rotate(147deg) brightness(98%) contrast(101%);
}
.button-arrow--left:focus::before {
  filter: invert(51%) sepia(46%) saturate(6916%) hue-rotate(147deg) brightness(98%) contrast(101%);
}

.block {
  color: #1b1d1d;
}
.block--title {
  font: 0.75rem "Montserrat", "Roboto", Arial, sans-serif;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.1875rem;
  text-transform: uppercase;
}
.block--text {
  font: 0.75rem "Montserrat", "Roboto", Arial, sans-serif;
  line-height: 2;
  font-weight: 500;
}

.cdk-overlay-pane--dialog-with-close-btn .mat-dialog-container {
  position: relative;
  padding-top: 40px;
}

@media (max-width: 767.98px) {
  .cdk-overlay-pane--fullscreen-dialog-for-mobile,
.cdk-overlay-pane--fullscreen-dialog-for-mobile .mat-dialog-container {
    max-width: 100% !important;
    max-height: 100% !important;
    min-height: 100vh !important;
    min-width: 100% !important;
  }
}
.cdk-overlay-pane--fullscreen-dialog-for-mobile .mat-dialog-content {
  max-height: 90vh;
}
.cdk-overlay-pane--fullscreen-dialog-for-mobile .mat-dialog-actions {
  display: block;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #33d3be;
}

.mat-tab-body-wrapper .mat-tab-body-content {
  overflow: hidden;
}

.mat-tab-label {
  background-color: transparent;
  color: #8d8e8e;
  font-weight: 700;
  letter-spacing: 1px;
}

.mat-tab-label.mat-tab-label-active {
  color: #1b1d1d;
}

.mat-tab-body-wrapper,
.mat-tab-body,
.mat-tab-body-content {
  margin: 0 -16px;
  padding: 0 16px;
}

.branding .mat-tab-label-active {
  color: #00a791;
  font: "Montserrat", "Roboto", Arial, sans-serif;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 0.1875rem;
  font-weight: 700;
}
.branding .mat-tab-label .mat-tab-label-content {
  font: "Montserrat", "Roboto", Arial, sans-serif;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 0.1875rem;
  font-weight: 600;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #33d3be;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #33d3be;
}

.mat-radio-button {
  width: 100%;
}
.mat-radio-button .mat-radio-outer-circle {
  border-color: #c6c6c6;
}
.mat-radio-button .mat-radio-label-content {
  font-size: 0.8125rem;
  line-height: 1rem;
  color: #1b1d1d;
  font-family: "Montserrat", "Roboto", Arial, sans-serif;
  font-weight: 500;
}
.mat-radio-button .mat-radio-label {
  display: flex;
  align-items: center;
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 100%;
}
.mat-radio-button .mat-radio-checked .mat-radio-label {
  border: 1px solid #33d3be;
  position: inside;
  background-color: rgba(51, 211, 190, 0.08);
}

.mat-select .mat-select-arrow {
  color: #8d8e8e;
}

.mat-form-field.mat-focused .mat-select-arrow {
  color: #00a791 !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #00a791;
}

.mat-form-field {
  font-size: 14px;
  width: 100%;
}
.mat-form-field .mat-form-field-underline {
  height: 0 !important;
}
.mat-form-field .mat-form-field-underline .mat-form-field-ripple {
  display: none;
}
.mat-form-field .mat-form-field-infix {
  padding: 16px !important;
  padding-top: 4px !important;
}
.mat-form-field .mat-form-field-flex {
  border: 1px solid #e0e0e0 !important;
  border-radius: 8px;
}
.mat-form-field .mat-focused {
  display: none;
}
.mat-form-field .mat-focused.mat-primary .mat-select-arrow {
  color: #00a791;
}
.mat-form-field .mat-form-field-flex:hover {
  border: 1px solid #00a791 !important;
}

.mat-form-field.mat-focused .mat-form-field-flex {
  border: 1px solid #00a791 !important;
}

.mat-calendar-table td > .mat-calendar-body-cell-content.mat-focus-indicator.mat-calendar-body-selected {
  background-color: #00a791 !important;
  color: #ffffff !important;
}
.mat-calendar-table td > .mat-calendar-body-cell-content:before {
  background: rgba(0, 167, 145, 0.1);
}
.mat-calendar-table td > .mat-calendar-body-cell-content:hover {
  background: rgba(0, 167, 145, 0.1);
}
.mat-calendar-table td .mat-calendar-body-cell mat-calendar-body-in-range ng-star-inserted {
  background: rgba(0, 167, 145, 0.1);
}
.mat-calendar-table .mat-calendar-body-in-range::before {
  background: rgba(0, 167, 145, 0.1);
}
.mat-calendar-table td > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: none !important;
}
.mat-calendar-table td .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: none;
  border: none;
  border-radius: none;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: none !important;
}

td .mat-calendar-body-cell .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-appearance-legacy .mat-form-field-prefix .mat-datepicker-toggle-default-icon,
.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
  width: 1.5em !important;
}

.mat-form-field-suffix {
  width: 30px !important;
  height: 30px !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background: #00a791;
}

.mat-datepicker-toggle-active {
  color: #00a791;
}

.mat-expansion-panel .mat-expansion-panel-header {
  font: 0.875rem "Montserrat", "Roboto", Arial, sans-serif;
  line-height: 1.7;
  font-weight: 500;
  color: #1b1d1d;
  padding: 4px 0px;
}
.mat-expansion-panel .mat-expansion-indicator::after {
  padding: 2px;
  margin-bottom: 3px;
}
.mat-expansion-panel .mat-expansion-panel-header.mat-expanded .mat-expansion-indicator::after {
  color: #00a791;
}
.mat-expansion-panel .mat-expansion-panel-content {
  font: 0.875rem "Montserrat", "Roboto", Arial, sans-serif;
  line-height: 1.7;
  font-weight: 500;
}
.mat-expansion-panel .mat-expansion-indicator {
  margin-right: 20px;
  margin-left: 10px;
}
.mat-expansion-panel .mat-content {
  word-break: break-word;
  align-items: center;
}

.mat-expansion-panel-spacing {
  margin: 0 0 !important;
}

.mat-expansion-panel.expansion-panel-stacked {
  box-shadow: none;
  border-bottom: 1px solid #f2f2f2;
  border-top: 1px solid #f2f2f2;
  border-radius: 0px;
}
@media (max-width: 767.98px) {
  .mat-expansion-panel.expansion-panel-stacked {
    padding: 0 8px;
  }
}
.mat-expansion-panel.expansion-panel-stacked.mat-expanded {
  box-shadow: 0 8px 20px 0 rgba(27, 29, 29, 0.1);
  background: #f8f8f8;
}
.mat-expansion-panel.expansion-panel-stacked .mat-expansion-panel-body {
  padding: 0;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}