.mat-form-field {
  font-size: 14px;
  width: 100%;

  .mat-form-field-underline {
    height: 0 !important;
    .mat-form-field-ripple {
      display: none;
    }
  }

  .mat-form-field-infix {
    padding: 16px !important;
    padding-top: 4px !important;
  }

  .mat-form-field-flex {
    border: 1px solid $eq-black-15 !important;
    border-radius: 8px;
  }

  .mat-focused {
    display: none;
  }

  .mat-focused.mat-primary .mat-select-arrow {
    color: $eq-dark-green;
  }

  .mat-form-field-flex {
    &:hover {
      border: 1px solid $eq-dark-green !important;
    }
  }
}

.mat-form-field.mat-focused {
  .mat-form-field-flex {
    border: 1px solid $eq-dark-green !important;
  }
}
