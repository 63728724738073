.list-item {
  position: relative;
  padding-left: 30px;

  &:before {
    content: '\2022';
    position: absolute;
    left: 10px;
    font-size: 10px;
    top: 4px;
  }
}
