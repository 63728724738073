.paragraph {
  @include typography(paragraph);

  &--big {
    @include typography(big-paragraph);
  }

  &--small {
    @include typography(small-paragraph);
  }

  &--white {
    color: $eq-white;
  }

  &--line-height-2 {
    line-height: 2;
  }

  &--grey {
    color: $eq-body-small-grey;
  }

  &--black-75 {
    color: $eq-black-75;
  }

  &--bold {
    font-weight: 700;
  }
}
