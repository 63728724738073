.link {
  @include typography(link);
  text-decoration: none;

  &--block {
    display: block;
  }

  &--strong {
    @include typography(bold-link);
    color: $eq-black-100;
  }

  &--highlighted {
    @include typography(bold-link);
    color: $eq-green;
  }

  &--big {
    @include typography('input-bold');
  }

  &--branding {
    color: $eq-green;
  }

  &--branding-dark {
    color: $eq-dark-green;
  }

  &--cramped {
    letter-spacing: 0;
  }

  &--grey {
    color: $eq-black-50;
  }

  &--black {
    color: $eq-black-100;

    &:focus,
    &:hover {
      color: $eq-black-75 !important;
    }
  }

  &--white {
    color: $eq-white;

    &:focus,
    &:hover {
      color: rgba($eq-white, 0.8) !important;
    }
  }

  &--underline {
    text-decoration: underline;
  }

  &--biger-font {
    font-size: calculateRem(14px);
  }

  &:focus,
  &:hover {
    color: $eq-dark-green;
  }
}
