.mat-select {
  .mat-select-arrow {
    color: $eq-black-50;
  }
}

.mat-form-field.mat-focused {
  .mat-select-arrow {
    color: $eq-dark-green !important;
  }
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $eq-dark-green;
}
