.secondary-header {
  @include typography(secondary-header);
  color: $eq-black-100;

  &--white {
    color: $eq-white;
  }

  &--sm-small {
    @include media-breakpoint-down(sm) {
      font-size: 32px;
    }
  }
}
