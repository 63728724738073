.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $eq-green;
}

.mat-tab-body-wrapper {
  .mat-tab-body-content {
    overflow: hidden;
  }
}

.mat-tab-label {
  background-color: transparent;
  color: $eq-black-50;
  font-weight: 700;
  letter-spacing: 1px;
}

.mat-tab-label.mat-tab-label-active {
  color: $eq-black-100;
}

.mat-tab-body-wrapper,
.mat-tab-body,
.mat-tab-body-content {
  margin: 0 #{-$grid-gutter-width/2};
  padding: 0 #{$grid-gutter-width/2};
}

.branding {
  .mat-tab-label-active {
    color: $eq-dark-green;
    font: $eq-font-regular;
    font-size: calculateRem(16px);
    line-height: calculateRem(16px);
    letter-spacing: calculateRem(3px);
    font-weight: 700;
  }

  .mat-tab-label .mat-tab-label-content {
    font: $eq-font-regular;
    font-size: calculateRem(16px);
    line-height: calculateRem(16px);
    letter-spacing: calculateRem(3px);
    font-weight: 600;
  }
}
