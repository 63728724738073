.mat-calendar-table {
  td
    > .mat-calendar-body-cell-content.mat-focus-indicator.mat-calendar-body-selected {
    background-color: $eq-dark-green !important;
    color: $eq-white !important;
  }

  td > .mat-calendar-body-cell-content:before {
    background: rgba($eq-dark-green, 0.1);
  }

  td > .mat-calendar-body-cell-content:hover {
    background: rgba($eq-dark-green, 0.1);
  }

  td .mat-calendar-body-cell mat-calendar-body-in-range ng-star-inserted {
    background: rgba($eq-dark-green, 0.1);
  }
  .mat-calendar-body-in-range::before {
    background: rgba($eq-dark-green, 0.1);
  }
  td
    > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: none !important;
  }

  td
    .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: none;
    border: none;
    border-radius: none;
  }
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: none !important;
}

td .mat-calendar-body-cell {
  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: rgba(0, 0, 0, 0.38);
  }
}

.mat-form-field-appearance-legacy
  .mat-form-field-prefix
  .mat-datepicker-toggle-default-icon,
.mat-form-field-appearance-legacy
  .mat-form-field-suffix
  .mat-datepicker-toggle-default-icon {
  width: 1.5em !important;
}

.mat-form-field-suffix {
  width: 30px !important;
  height: 30px !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background: $eq-dark-green;
}

.mat-datepicker-toggle-active {
  color: $eq-dark-green;
}
