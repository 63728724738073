.btn {
  display: inline-block;
  @include typography(normal-button);
  padding: 15px 23px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid transparent;

  @include media-breakpoint-down(md) {
    @include typography(small-button);
    padding: 11px 20px;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &--primary {
    background: linear-gradient(45deg, #22c7d0, #46dfaa, #46dfaa, #22c7d0);
    color: $eq-white;
    background-size: 300% 100%;
    transition: background-position ease 0.5s, box-shadow ease 0.3s;

    &:focus,
    &:hover {
      color: $eq-white;
      box-shadow: $eq-box-shadow-button;
      background-position: 100% 100%;
    }
  }

  &--white-and-black {
    background: $eq-white;
    color: $eq-black-100;
    transition: color ease 0.3s, background-color ease 0.3s,
      box-shadow ease 0.3s;
    border: 1px solid black;

    &:focus,
    &:hover {
      color: $eq-white;
      background: $eq-black-100;
      box-shadow: $eq-box-shadow-button;
    }
  }

  &--black-and-white {
    background: $eq-black-100;
    color: $eq-white;
    transition: color ease 0.3s, background-color ease 0.3s,
      box-shadow ease 0.3s;
    border: 1px solid black;

    &:focus,
    &:hover {
      color: $eq-black-100;
      background: $eq-white;
      box-shadow: $eq-box-shadow-button;
    }
  }

  &--white {
    background: $eq-white;
    color: $eq-black-100;
    border-top: 1px solid transparent;
    transition: background-color ease 0.3s, box-shadow ease 0.3s;

    &:focus,
    &:hover {
      background: $eq-black-5;
    }
  }

  &--warning {
    background: $eq-warning-dark;
    color: $eq-white;
  }

  &--big {
    padding: 19px 47px;
  }

  &--sm-small {
    @include media-breakpoint-down(sm) {
      padding: 15px 23px;
    }
  }

  &--link {
    color: $eq-black-50;
    padding: 8px 16px;
    &:hover,
    &:focus {
      color: $eq-black-100;
    }
  }
}
