.button-icon-arrow {
  @include typography(normal-button);
  @include media-breakpoint-up(md) {
    width: 408px;
  }
  width: 100%;
  padding: 19px 16px;
  border-radius: 8px;
  background-color: $eq-black-3;
  display: flex;
  align-items: center;
  max-width: 100%;
  color: $eq-black-75;
  padding-right: 16px;
  text-align: left;
  overflow: auto;

  &:before {
    width: 24px;
    height: 24px;
    position: relative;
    flex-shrink: 0;
    margin-right: 24px;
    @include media-breakpoint-down(xs) {
      margin-right: 10px;
    }
  }

  &--bank-account::before {
    background-image: url('/assets/icons/bank-account.svg');
    content: '';
    display: block;
  }

  &--credit-card::before {
    background-image: url('/assets/icons/credit-card.svg');
    content: '';
    display: block;
  }

  &::after {
    content: '';
    display: block;
    position: relative;
    background-image: url('/assets/icons/32px_arrow-right.svg');
    width: 25px;
    height: 25px;
    margin-left: auto;
    flex-shrink: 0;
  }

  &:focus,
  &:hover {
    background-color: $eq-white;
    cursor: pointer;
    box-shadow: $eq-box-shadow-button;
  }
}
