.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: $eq-green;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $eq-green;
}

.mat-radio-button {
  width: 100%;
  .mat-radio-outer-circle {
    border-color: $eq-black-35;
  }

  .mat-radio-label-content {
    font-size: calculateRem(13px);
    line-height: calculateRem(16px);
    color: $eq-black-100;
    font-family: $eq-font-regular;
    font-weight: 500;
  }

  .mat-radio-label {
    display: flex;
    align-items: center;
    padding: 16px;
    border: 1px solid $eq-black-15;
    border-radius: 8px;
    width: 100%;
  }

  .mat-radio-checked .mat-radio-label {
    border: 1px solid $eq-green;
    position: inside;
    background-color: rgba(51, 211, 190, 0.08);
  }
}
