$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1340px,
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1312px,
) !default;

$grid-gutter-width: 32px !default;

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: (
      $spacer * 0.75,
    ),
    4: $spacer,
    5: (
      $spacer * 1.5,
    ),
    6: (
      $spacer * 2,
    ),
    7: (
      $spacer * 2.5,
    ),
    8: (
      $spacer * 3,
    ),
    9: (
      $spacer * 4,
    ),
    10: (
      $spacer * 5,
    ),
    11: (
      $spacer * 6,
    ),
    12: (
      $spacer * 8,
    ),
    13: (
      $spacer * 10,
    ),
  ),
  $spacers
);
