.section-header {
  @include typography(section-header);

  &--white {
    color: $eq-white;
  }

  &--medium {
    @include typography(medium-section-header);
  }

  &--normal-weight {
    font-weight: 500;
  }
}
