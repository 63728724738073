.small-section-header {
  @include typography(small-section-header);

  &--bold {
    font-weight: 700;
  }

  &--black {
    color: $eq-black-100;
  }
}
